import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { AlertService } from '../../shared/services/alert.service';

@Component({
    selector: 'app-forgot-password',
    templateUrl: './forgot-password.component.html',
})
export class ForgotPasswordComponent implements OnInit {
    public forgotForm: UntypedFormGroup;

    constructor(
        private readonly router: Router,
        private readonly route: ActivatedRoute,
        private readonly formBuilder: UntypedFormBuilder,
        private readonly authService: AuthService,
        private readonly alertService: AlertService,
    ) {}

    public ngOnInit(): void {
        this.forgotForm = this.formBuilder.group({
            email: ['', [Validators.required, Validators.email]],
        });
    }

    public resetPassword(email: string): void {
        this.authService
            .sendEmailPasswordReset(email)
            .pipe(first())
            .subscribe(
                (): void => {
                    this.alertService.success({
                      title: 'E-mail enviado',
                      message: 'Se ha enviado un e-mail para reiniciar la contraseña'
                    }).then(()=>this.finish());
                },
                (err: { code: string; message: string }): void => {
                    console.log(err);
                  this.alertService.error({
                    title: 'Error',
                    message: 'Ha ocurrido un error con el reinicio de la contraseña'
                  }).then(()=>this.finish());
                }
            );
    }

    public finish(): void {
        this.router.navigate(['..'], { relativeTo: this.route });
    }
}
