import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { combineLatest, from, Observable, switchMap } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  AngularFirestore,
  AngularFirestoreCollection,
  CollectionReference,
  Query,
} from '@angular/fire/compat/firestore';
import { collection, doc, Firestore, getFirestore, updateDoc } from 'firebase/firestore';
import { UserInterface } from '../interfaces/user.interface';
import { deepConvertDates } from '../../shared/utils/utils';
import { User } from '../models/user.model';
import { CONSTANTS, QUERYS_DISJUNCTIVE_LIMIT } from '../../shared/utils/constants';
import { FIRESTORE_COLLECTION, LAST_LOG_SEEN_BY_MEDIA } from '../../shared/constants';

const USERS_COLLECTION = FIRESTORE_COLLECTION.USERS;

interface UpdateLastNotificationSeenOnMediaParams {
  user$: Observable<UserInterface>;
  mediaId$: Observable<string>;
}

@Injectable()
export class UserService {
  public usersCollection: AngularFirestoreCollection<UserInterface>;
  private readonly db: Firestore = getFirestore();
  private readonly collection = collection(this.db, FIRESTORE_COLLECTION.USERS);

  public constructor(
    private readonly firestore: AngularFirestore,
    private readonly httpClient: HttpClient
  ) {
    this.usersCollection = this.firestore.collection<UserInterface>(USERS_COLLECTION);
  }

  public getAll(): Observable<UserInterface[]> {
    return this.usersCollection.valueChanges().pipe(map(User.mapFromFirebase));
  }

  public getAllAllowed(sitesIds: string[]): Observable<UserInterface[]> {
    //TODO esta función se podría realizar utilizando el query de in de firebase. Sin necesidad de hacer una consulta de getAllBySite por cada rol y media
    const queries = new Array<Observable<UserInterface[]>>();
    const totalSites = sitesIds.length;
    const numberOfSlices = Math.ceil(totalSites / 10);
    for (let i = 0; i < numberOfSlices; i++) {
      const pending = (i + 1) * 10 < totalSites ? 10 : totalSites - i * 10;
      const medias = sitesIds.slice(i * 10, i * 10 + pending);
      const users$ = this.getAllBySite(medias);
      queries.push(users$);
    }
    return combineLatest(queries).pipe(
      map((data: UserInterface[][]): UserInterface[] => {
        const users = [].concat(...data);
        return users.filter(
          (user: UserInterface, index: number): boolean =>
            users.findIndex((u: UserInterface): boolean => user.uid === u.uid) === index
        );
      })
    );
  }

  public getAllBySite(siteIds: string[]): Observable<UserInterface[]> {
    const chunkedSiteIds = this.chunkArray(siteIds, QUERYS_DISJUNCTIVE_LIMIT);
    const queries = chunkedSiteIds.map((chunk) =>
      this.firestore
        .collection<UserInterface>(
          USERS_COLLECTION,
          (ref: CollectionReference): Query => ref.where('medias', 'array-contains-any', chunk)
        )
        .valueChanges()
    );

    return combineLatest(queries).pipe(
      map((results) => results.flat()),
      map(User.mapFromFirebase)
    );
  }

  public get(userId: string): Observable<UserInterface> {
    return this.usersCollection.doc<UserInterface>(userId).valueChanges().pipe(map(User.fromFirebase));
  }

  public add(user: UserInterface): Observable<void> {
    const userId = this.firestore.createId();
    user.uid = userId;
    return from(this.usersCollection.doc<UserInterface>(userId).set(Object.assign({}, user)));
  }

  public addWithCustomId(userId: string, user: UserInterface): Observable<void> {
    return from(this.usersCollection.doc<UserInterface>(userId).set(Object.assign({}, user)));
  }

  public deleteFromAuthFirebase(userId: string): Observable<void> {
    const DELETE_USER_FROM_AUTH = CONSTANTS.API_FUNCTION_DELETE_USER_FROM_AUTH.replace(':userId', userId);
    return this.httpClient.delete<void>(DELETE_USER_FROM_AUTH);
  }

  public delete(userId: string): Observable<void> {
    return from(this.usersCollection.doc<UserInterface>(userId).delete());
  }

  public update(userId: string, dataToUpdate: Partial<UserInterface>): Observable<void> {
    return from(this.usersCollection.doc<UserInterface>(userId).update(deepConvertDates(dataToUpdate)));
  }

  updateLastNotificationSeenOnMedia({ user$, mediaId$ }: UpdateLastNotificationSeenOnMediaParams): Observable<void> {
    // Need user$ and mediaId$ as argument to avoid circular dependency
    return combineLatest({ user: user$, mediaId: mediaId$ }).pipe(
      switchMap(({ user, mediaId }) =>
        from(updateDoc(doc(this.collection, user?.uid), [LAST_LOG_SEEN_BY_MEDIA, mediaId].join('.'), new Date()))
      )
    );
  }

  private chunkArray(array: string[], chunkSize: number): string[][] {
    const chunks = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      chunks.push(array.slice(i, i + chunkSize));
    }
    return chunks;
  }
}
