import { HYPHENATED_PLACEHOLDER } from "./constants";

export function toRoundedToEndOfDay(date: Date): Date {
  const result = new Date(date);
  result.setHours(23, 59, 59, 999);
  return result;
}

function isValidDate(date: Date): boolean {
  return date instanceof Date && !isNaN(date.getTime());
}

export function localeStringLabel(date: string | number | Date): string {
  // TODO no debería darse la respuesta predeterminada de EMPTY_PLACEHOLDER. Cada componente que llame a esta función, debe poder controlar qué mostrar
  if (!date) return HYPHENATED_PLACEHOLDER;
  const attempt = new Date(date);
  return isValidDate(attempt) ? attempt.toLocaleDateString() : HYPHENATED_PLACEHOLDER;
}
