import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, of, switchMap, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';
import { RoleService } from '../services/role.service';
import { AlertService } from '../../shared/services/alert.service';
import { NOTIFY_MESSAGE, SYSTEM_MESSAGES } from '../../shared/utils/constants-messages/constants-messages';
import { UserInterface } from '../interfaces/user.interface';

class NotValidUSer extends Error {
  constructor() {
    super(SYSTEM_MESSAGES.USER_NOT_VALID);
  }
}

class NoAccessError extends Error {
  constructor() {
    super(SYSTEM_MESSAGES.USER_HAS_NOT_ACCESS);
  }
}

function isValidUser(user: UserInterface): boolean {
  return user && user.emailVerified && user.active && user.medias?.length > 0;
}

export const authGuard: CanActivateFn = (
  next: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
): Observable<boolean | UrlTree> | boolean | UrlTree => {
  const router = inject(Router);
  const authService = inject(AuthService);
  const roleService = inject(RoleService);
  const alertService = inject(AlertService);
  const EXIT_PATH = { LOGIN: ['/login'], MEDIAS: ['/medias'] };

  const permission = next.data?.permission;
  if (!permission) {
    console.error(`authGuard called without permission config on route ${state.url}`);
    return true;
  }

  return authService.user$.pipe(
    tap((user) => {
      if (!isValidUser(user)) throw new NotValidUSer();
    }),
    switchMap(() => roleService.userRolesCombinedPermissions$),
    map((permissions) => permissions[permission]),
    tap((hasPermission) => {
      if (!hasPermission) throw new NoAccessError();
    }),
    catchError((error) => {
      const { isInitialNavigation } = router.getCurrentNavigation()?.extras.state ?? {};
      if (isInitialNavigation) {
        return throwError(() => error); // Needed to iterate over initial routes on media selection
      }

      let path: string[];
      let message: string;

      if (error instanceof NotValidUSer) {
        path = EXIT_PATH.LOGIN;
        message = NOTIFY_MESSAGE.CORE.USER_NOT_VALID;
      }

      if (error instanceof NoAccessError) {
        path = EXIT_PATH.MEDIAS;
        message = NOTIFY_MESSAGE.CORE.NO_ACCESS;
      }

      if (path) {
        alertService
          .error({
            title: NOTIFY_MESSAGE.CORE.TITLE,
            message,
          })
          .then(); // No wait
        return of(router.createUrlTree(path));
      }

      return throwError(() => error);
    })
  );
};
