<div class="container d-flex h-100">
    <div class="row w-100 align-self-center">
        <div class="col-md-8 mx-auto">
            <div class="c-login-card card">
                <div class="card-header text-center bg-primary-color py-4">
                    <img class="c-login-card__logo" src="/assets/images/logo-white.svg" alt="Hipatia Logo" />
                </div>
                <!--Card content-->
                <div class="card-body">
                    <!-- Form -->
                    <form [formGroup]="forgotForm" (ngSubmit)="resetPassword(forgotForm.get('email').value)">
                        <div class="row">
                            <div class="col-md-12">
                                <mat-form-field
                                    class="c-mat-form c-mat-form--no-padding col-md-12"
                                    appearance="outline"
                                    color="primary"
                                >
                                    <mat-label>Correo electrónico</mat-label>
                                    <input
                                        matInput
                                        formControlName="email"
                                        placeholder="Introduzca su correo electrónico"
                                        required
                                    />
                                    <mat-icon matSuffix color="primary" fontSet="fas" fontIcon="fa-envelope"></mat-icon>
                                    <mat-error *ngIf="forgotForm.get('email').hasError('email')">
                                        El e-mail debe ser válido
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="row mt-3 justify-content-around">
                            <div class="col-md-3">
                                <button
                                    mat-raised-button
                                    class="h-100 w-100"
                                    color="primary"
                                    type="submit"
                                    [disabled]="forgotForm.invalid"
                                >
                                    Recuperar
                                </button>
                            </div>
                            <div class="col-md-3">
                                <button
                                    mat-raised-button
                                    class="h-100 w-100"
                                    type="button"
                                    color="primary"
                                    routerLink="/login"
                                >
                                    Cancelar
                                </button>
                            </div>
                        </div>
                    </form>
                    <!-- Form -->
                </div>
            </div>
        </div>
    </div>
</div>
