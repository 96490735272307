import { Component, OnInit, OnChanges, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SectionInterface } from 'src/app/core/interfaces/filter.interface';
import { ConstantsFilters } from 'src/app/shared/utils/constants-filters';
import { TriggersTypeToStringPipe } from 'src/app/shared/pipes/triggers-type-to-string.pipe';
import { DatasourcesTypeToStringPipe } from 'src/app/shared/pipes/datasources-type-to-string.pipe';
import TopicSubscriptionInterface from 'src/app/core/interfaces/push-notification/topic-subscription.interface';

@Component({
  selector: 'app-generic-filter-select',
  templateUrl: './generic-filter-select.component.html',
  styleUrls: ['./generic-filter-select.component.scss'],
})
export class GenericFilterSelectComponent implements OnInit, OnChanges {
  @Input() sections: SectionInterface[];
  @Input() defaultSection: number;
  @Input() componentList: Array<any>;
  @Input() componentType: string;
  @Input() componentTypes: number[];
  @Input() idFieldOfList: string;
  @Input() nameFieldOfList: string;
  @Input() states: string[];
  @Input() typeWall: boolean;
  @Input() advertisers: string[];
  @Input() programDatesList: string[];
  @Input() date: { from: string; until: string };
  @Input() vendors: string[];
  @Input() sites: string[];
  @Input() topics: TopicSubscriptionInterface[];
  @Input() titles: Array<string>;
  @Input() inputNameText: string;
  @Input() inputPlaceholderNameText: string;
  @Input() inputIdText: string;
  @Input() inputPlaceholderIdText: string;
  @Input() dates: { begin: Date; end: Date };
  @Input() downloadButtonText: string;
  @Input() modules: string[];
  @Output() filtersUpdated = new EventEmitter<{
    id: string;
    name: string;
    title: string;
    filter: number;
    type: number;
    topic: TopicSubscriptionInterface;
    dates: [{ startDate: Date; endDate: Date }];
  }>();
  @Output() exportListAsCSV = new EventEmitter();

  private unsubscribe: Subject<void> = new Subject();

  public filterForm: UntypedFormGroup;

  FILTER_VALUE_ALL = ConstantsFilters.FILTER_VALUE_ALL;

  constructor(
    private readonly formBuilder: UntypedFormBuilder,
    private readonly triggersTypeToStringPipe: TriggersTypeToStringPipe,
    private readonly datasourcesTypeToStringPipe: DatasourcesTypeToStringPipe
  ) {}

  ngOnInit() {
    if (this.componentList && this.componentList.length) this.addAttributesInList();
    this.createFilterForm();
    this.subscribeToFilterForm();
  }

  ngOnChanges() {
    this.addAttributesInList();
  }

  private addAttributesInList() {
    switch (this.componentType) {
      case ConstantsFilters.ADMIN_DIMENSION_TYPE:
      case ConstantsFilters.ADMIN_ROLE_TYPE:
      case ConstantsFilters.NEWSLETTER_TYPE:
      case ConstantsFilters.NOTIFICATION_APP_TYPE:
      case ConstantsFilters.NEWSLETTER_TEMPLATE_TYPE:
      case ConstantsFilters.AUDIENCE_TYPE:
        this.componentList = this.componentList.map(component => ({ ...component, name: component.title }));
        break;
      case ConstantsFilters.TOPIC_TYPE:
        this.componentList = this.componentList.map(component => ({ ...component, name: component.fullName }));
        break;
      case ConstantsFilters.HISTORICAL_SEGMENT_TYPE:
        this.componentList = this.componentList.map(component => ({
          ...component,
          id: component.idSegment,
          name: component.segmentName,
        }));
        break;
      case ConstantsFilters.REALTIME_SEGMENT_TYPE:
        this.componentList = this.componentList.map(component => ({ ...component, id: component.idRule }));
        break;
      case ConstantsFilters.NOTIFICATION_TYPE:
        this.componentList = this.componentList.map(component => ({
          ...component,
          name: component.firebasePayload.message.title,
        }));
        break;
      case ConstantsFilters.TOPIC_APP_TYPE:
        this.componentList = this.componentList.map(component => ({ ...component, name: component.topic }));
        break;
      case ConstantsFilters.ADMIN_AUTHENTICATION_TYPE:
        this.componentList = this.componentList.map(component => ({
          ...component,
          name: component.groupId,
          id: component.mediaId,
        }));
        break;
      default:
        break;
    }
  }

  public takeUnicAttributeObjects(attribute: string): any[] {
    //TODO agregar mayor claridad a la variable "v"
    return this.componentList.filter(
      (value, index, self) => self.findIndex(v => v[attribute] === value[attribute]) === index
    );
  }

  private createFilterForm(): void {
    this.filterForm = this.formBuilder.group({
      name: [''],
      id: [''],
      filter: [this.defaultSection || this.filterValueInitialCondition()],
      type: [0],
      topic: [''],
      shared: [''],
      advertiserShared: [''],
      mediaOwner: [''],
      state: [undefined],
      programDates: [0],
      advertiser: [undefined],
      vendor: [undefined],
      site: [0],
      date: null,
      typeWall: [],
      modules: [1],
      sites: [0],
    });
  }

  private filterValueInitialCondition(): number {
    return [
      ConstantsFilters.TRIGGER_FUNCTION_TYPE,
      ConstantsFilters.PROMETEO_TEMPLATE_TYPE,
      ConstantsFilters.TRIGGER_TEMPLATE_TYPE,
      ConstantsFilters.DATASOURCE_TYPE,
      ConstantsFilters.ADMIN_ROLE_TYPE,
      ConstantsFilters.ADMIN_UTM_CAMPAIGN_TYPE,
    ].includes(this.componentType)
      ? 1
      : 2;
  }

  private subscribeToFilterForm(): void {
    this.filterForm.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe((): void => this.filter());
  }

  public filter(): void {
    this.filtersUpdated.emit(this.filterForm.getRawValue()); // comprobar si emite bien los cambios en el rango de fechas si no cambiarlo y separar el de dates como esta en stadistics
  }

  public getComponentTypeName(type: number): string {
    switch (this.componentType) {
      case ConstantsFilters.NEWSLETTER_TYPE:
      case ConstantsFilters.TRIGGER_TEMPLATE_TYPE:
      case ConstantsFilters.TRIGGER_TYPE:
        return this.triggersTypeToStringPipe.transform(type);
      case ConstantsFilters.DATASOURCE_TYPE:
        return this.datasourcesTypeToStringPipe.transform(type).toUpperCase();
      default:
        break;
    }
  }

  public exportContentsListAsCSV() {
    this.exportListAsCSV.emit();
  }
}
