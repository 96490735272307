import { CONSTANTS } from './constants';
import { TriggerInterface } from 'src/app/core/interfaces/trigger.interface';
import { NewslettersSubscriptionInterface } from 'src/app/core/interfaces/newsletters-subscription.interface';
import { PushSubscriptionInterface } from 'src/app/core/interfaces/push-subscription.interface';
import { FirebaseFCM } from 'src/app/core/interfaces/firebaseFCM.interface';
import * as download from 'downloadjs';
import { QueryBuilderComponent, RuleModel } from '@syncfusion/ej2-angular-querybuilder';
import { ConstantStyles, triggerStyles } from './constants-styles';
import { SegmentHistoricInterface } from 'src/app/core/interfaces/segment-historic.interface';
import { SegmentOperationsHistoricInterface } from 'src/app/core/interfaces/segment-operations-historic.interface';
import { SegmentRealTimeInterface } from 'src/app/core/interfaces/query-builder/segment-real-time.interface';
import { SiteInterface } from 'src/app/core/interfaces/site.interface';
import { MediaInterface } from 'src/app/core/interfaces/media.interface';
import * as moment from 'moment';
import Swal, { SweetAlertResult, SweetAlertIcon } from 'sweetalert2';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { first, switchMap } from 'rxjs/operators';
import { HttpErrorResponse, HttpParams } from '@angular/common/http';
import { ConstantsStatistics } from 'src/app/shared/utils/constants-statistics';
import ConstantMessages, { NOTIFY_TYPE } from 'src/app/shared/utils/constants-messages/constants-messages';
import { Utms } from 'src/app/core/interfaces/utms.interface';
import { ConstantsSegments } from './constants-segments';
import { AllServicesInterface } from 'src/app/core/interfaces/all-services.interface';
import { SegmentService } from 'src/app/core/services/segment.service';
import { Observable, combineLatest, of } from 'rxjs';
import { Buffer } from 'buffer';
import { ConstantsStylesWidgetsTriggers } from './constants-triggers';
import { PUSH_NOTIFICATION_STATISTIC_DEVICES, PUSH_NOTIFICATION_STATISTIC_STATUS } from 'src/app/components/push-notifications/web/scheduled/config/constants';
import firebase from 'firebase/compat/app';
import { environment } from '../../../environments/environment';
import { ActionInterface } from 'src/app/core/interfaces/action.interface';
import { ObjectKey } from '../types/types';

export class Utils {
  public static generateGUID(): string {
    function S4() {
      return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
    }

    return S4() + S4();
  }

  public static generateRandomId(prefix, digits = 5){
    return Array(digits).fill(1).reduce((prev) => prev.concat(Math.floor(Math.random() * 10)), prefix);
  }

  public static areEqualArrays(arr1: any[], arr2: any[]): boolean {
    if (arr1.length !== arr2.length) return false;
    for (let i = arr1.length; i--; ) {
      if (arr1[i] !== arr2[i]) return false;
    }
    return true;
  }

  public static checkForRepeated = (value: string, matchString: string): string => {
    while (value.indexOf(matchString) !== value.lastIndexOf(matchString)) {
      value = value.replace(matchString, '');
    }
    return value;
  };

  public static removeDuplicates(arr1: { id: string }[]): any[] {
    const seen = {};
    const out = [];
    const len = arr1.length;
    let j = 0;
    for (let i = 0; i < len; i++) {
      const item = arr1[i].id;
      if (seen[item] !== 1) {
        seen[item] = 1;
        out[j++] = arr1[i];
      }
    }
    return out;
  }

  public static removeInvalidFunction = (value: string, matchString: string): string => {
    const matchString1 = `${matchString};`;
    const matchString2 = `${matchString} ;`;
    value = value.split(matchString1).join('').split(matchString2).join('').split(matchString).join('');
    return value;
  };

  public static removeMinHeight(html: string): string {
    return html.replace('class="u_body" style="min-height: 100vh;', 'class="u_body" style="');
  }

  public static removeHideDesktopMobileStyle(style: string, html: string, lastHtml: string): string {
    return `${style}
        ${this.checkIfHideMobileIsActive(html, 'hide-mobile') ? 'class="hide-mobile"' : ''}
        ${this.checkIfHideMobileIsActive(html, 'hide-desktop') ? 'class="hide-desktop"' : ''}
        ${lastHtml}`;
  }

  public static getUniqueItemsFromObjectArrays(mainArray: any[], concatArray: any[], attribute: string): any[] {
    const mainItems = mainArray.map((element) => element[attribute]);
    const uniqueItems = concatArray.filter((element) => !mainItems.includes(element[attribute]));
    return mainArray.concat(uniqueItems);
  }

  public static replaceHtmlByType({html, id, type, media, addCloseButton, preview}): any {
    const styles = triggerStyles[type];
    //if addCloseButton is false, delete the button tag from the html
    if(type === CONSTANTS.PROMETEO_TRIGGERS_BOX_TYPE) {
      html = ConstantsStylesWidgetsTriggers.triggerBoxTemplate;
      html = html.replaceAll(':widgetId', id).replace(':mediaId', media).replace(':WIDGET_HTML', preview);
      return html;
    }

    html = ConstantsStylesWidgetsTriggers.triggerWidgetTemplate;
    if(!addCloseButton) {
      const buttonDivTagRegex = /<div style="display:flex; flex-direction:row-reverse;">[\s\S]*?<\/div>/g;
      html = html.replace(buttonDivTagRegex, '');
    } else {
      html = html.replace('TRIGGERSTYLES', styles.closeButtonStyle);
    }
    html = html.replace(':STYLE', styles.triggerStyle).replaceAll(':widgetId', id).replace(':mediaId', media).replace(':WIDGET_HTML', preview);
    return html;
  }

  public static getStylesForTriggerType(type: number, html: string, addCloseButton: boolean): string {
    let response: string;
    if (addCloseButton) {
      const ubodySplit = html.split('class="u_body"');
      const value = ubodySplit[1].split('>');
      value[1] = ConstantStyles.closeButton + value[1];
      ubodySplit[1] = value.join('>');
      html = ubodySplit.join('class="u_body"');
    }
    const htmlSplitted = html.split('<body');
    switch (type) {
      case CONSTANTS.PROMETEO_TRIGGERS_STICKY_TOP_TYPE:
        htmlSplitted[1] = this.removeHideDesktopMobileStyle(
          ConstantStyles.stickyTopBodyModalStyle,
          html,
          htmlSplitted[1]
        );
        response = htmlSplitted.join('');
        response = this.removeMinHeight(response);
        if (addCloseButton) {
          response = response.replace('TRIGGERSTYLES', ConstantStyles.stickyTopCloseButtonStyles);
        }
        break;
      case CONSTANTS.PROMETEO_TRIGGERS_STICKY_BOTTOM_TYPE:
        htmlSplitted[1] = this.removeHideDesktopMobileStyle(ConstantStyles.stickyBottomStyle, html, htmlSplitted[1]);
        response = htmlSplitted.join('');
        response = this.removeMinHeight(response);
        if (addCloseButton) {
          response = response.replace('TRIGGERSTYLES', ConstantStyles.stickyBottomCloseButtonStyles);
        }
        break;
      case CONSTANTS.PROMETEO_TRIGGERS_SIDEBAR_TYPE:
        htmlSplitted[1] = this.removeHideDesktopMobileStyle(ConstantStyles.sideBarStyle, html, htmlSplitted[1]);
        response = htmlSplitted.join('');
        if (addCloseButton) {
          response = response.replace('TRIGGERSTYLES', ConstantStyles.sideBarCloseButtonStyle);
        }
        break;
      case CONSTANTS.PROMETEO_TRIGGERS_MODAL_TYPE:
        htmlSplitted[1] = this.removeHideDesktopMobileStyle(ConstantStyles.modalStyle, html, htmlSplitted[1]);
        html = htmlSplitted.join('');
        html = this.removeMinHeight(html);
        const htmlSplitted4 = html.split('class="u_body" style="');
        htmlSplitted4[1] = `${ConstantStyles.modalBodyStyle}
                  ${htmlSplitted4[1]}`;
        response = htmlSplitted4.join('');
        if (addCloseButton) {
          response = response.replace('TRIGGERSTYLES', ConstantStyles.modalCloseButtonStyle);
        }
        break;
      case CONSTANTS.PROMETEO_TRIGGERS_BOX_TYPE:
        htmlSplitted[1] = ` id="body_modal"${htmlSplitted[1].replace(
          /<div style="max-width: 500px;/g,
          '<div style="'
        )}`;
        html = htmlSplitted.join('<body');
        html = this.removeMinHeight(html);
        response = html;
        break;
      case CONSTANTS.PROMETEO_TRIGGERS_NONE_TYPE:
        response = html;
        break;
    }
    response = response.replace('<body', '<div');
    response = response.replace('</body', '</div');
    response = response.replace('body,html{padding:0;margin:0}html{box-sizing:border-box}', '');
    response = response.replace(
      'html{font-size:14px;-ms-overflow-style:scrollbar;-webkit-tap-highlight-color:rgba(0,0,0,0)}body{font-family:Arial,Helvetica,sans-serif;font-size:1rem;line-height:1.5;color:#373a3c;background-color:#fff}',
      ''
    );
    response = response.replace('<meta charset="utf-8">', '');
    response = response.replace('<meta http-equiv="x-ua-compatible" content="ie=edge">', '');
    response = response.replace('<title></title>', '');
    response = response.replace('<meta name="description" content="">', '');
    response = response.replace('<meta name="viewport" content="width=device-width, initial-scale=1">', '');
    response = response.replace('a { color: #0000ee; text-decoration: underline; }', '');

    response = response.replace('a:hover { color: #0000ee; text-decoration: underline; }', '');

    response = response.replace('p,ul{margin:0}', '');
    response = response.replace('*,:after,:before{box-sizing:inherit}', '');
    const splittedByStyle = response.split('<style type="text/css">');
    const splittedByClosingStyle = splittedByStyle[1].split('</style>');
    splittedByClosingStyle[0] = this.processStyles(splittedByClosingStyle[0]);
    splittedByStyle[1] = splittedByClosingStyle.join('</style>');
    response = splittedByStyle.join('<style type="text/css">');
    response = response.replace('body { font-family', '.u_content_button { cursor: pointer; } a { cursor: pointer; } .u_body { font-family');
    return response;
  }

  private static processStyles(css): string {
    css = css.replace('a { color: #0000ee; text-decoration: underline; }', '');

    css = css.replace('a:hover { color: #0000ee; text-decoration: underline; }', '');
    const arrayStyle = css.split('}');
    const result = arrayStyle
      .map((string) => {
        if (string.includes('media')) {
          const resultSplit = string.split('{');
          resultSplit[1] = '#body_modal ' + resultSplit[1];
          return resultSplit.join('{');
        } else if ((!string.includes('html') && string.includes('-webkit')) || string === '\n' || !string.length) {
          return string;
        } else {
          const updatedString = string;
          if (updatedString.includes(',') && !updatedString.includes('color')) {
            return updatedString
              .split(',')
              .map((string) => {
                return '#body_modal ' + string;
              })
              .join(',');
          } else {
            return '#body_modal ' + updatedString;
          }
        }
      })
      .join('}');
    return result;
  }

  private static checkIfHideMobileIsActive(html, cssClass): boolean {
    const splittedHtml = html.split('row_1');
    return splittedHtml.length >= 2 && splittedHtml[1].includes(cssClass);
  }

  public static mapperTriggerFromNewsletter(newsletter: NewslettersSubscriptionInterface): TriggerInterface {
    const trigger: TriggerInterface = {
      id: newsletter.id,
      active: newsletter.active,
      ampConfig: newsletter.ampConfig,
      defaultBlockArticle: false,
      name: newsletter.title,
      description: '',
      type: newsletter.type,
      triggerType: CONSTANTS.TRIGGER_NEWSLETTERS_TYPE,
      // templateId: newsletter.templateId, // TODO está en TriggerInterface pero no en NewslettersSubscriptionInterface
      siteId: newsletter.siteId,
      siteType: newsletter.siteType,
      segments: newsletter.segments,
      html: newsletter.html,
      javascript: newsletter.javascript,
      frequency: newsletter.frequency,
      identifier: newsletter.identifier,
      layout: null,
      priority: newsletter.priority,
      behaviour: null,
      campaignTitle: '',
      campaignSubject: '',
      design: '',
      title: '',
      body: '',
      urlImage: '',
      urlAction: '',
      topic: newsletter.topic,
      createdAt: newsletter.createdAt,
      updatedAt: newsletter.updatedAt,
      updatedBy: newsletter.updatedBy,
      deletedBy: newsletter.deletedBy,
      tag: '',
      programDates: newsletter.programDates,
      isCompatible: newsletter.isCompatible,
      isAmp: newsletter.isAmp,
      compatibleTriggers: newsletter.compatibleTriggers,
      //Temporal while the programElement function is still running
      isNewScheduleFormat: newsletter.isNewScheduleFormat,
    };

    return trigger;
  }

  public static mapperTriggerFromPushNotification(pushNotification: PushSubscriptionInterface): TriggerInterface {
    const trigger: TriggerInterface = {
      id: pushNotification.id,
      defaultBlockArticle: false,
      active: pushNotification.active,
      name: pushNotification.name,
      description: pushNotification.description,
      type: CONSTANTS.PUSH_SUBSCRIPTION_ENTITY_TYPE,
      siteId: pushNotification.siteId,
      siteType: pushNotification.siteType,
      segments: pushNotification.segments,
      html: pushNotification.html,
      javascript: pushNotification.javascript,
      frequency: pushNotification.frequency,
      layout: null,
      behaviour: null,
      title: '',
      body: '',
      topic: '',
      createdAt: pushNotification.createdAt,
      updatedAt: pushNotification.updatedAt,
      updatedBy: pushNotification.updatedBy,
      tag: pushNotification.tag,
      priority: pushNotification.priority,
      triggerType: CONSTANTS.TRIGGER_TYPE,
      campaignTitle: '',
      campaignSubject: '',
      design: '',
      urlImage: '',
      urlAction: '',
      programDates: pushNotification.programDates,
      compatibleTriggers: pushNotification.compatibleTriggers,
      //Temporal while the programElement function is still running
      isNewScheduleFormat: pushNotification.isNewScheduleFormat,
    };

    return trigger;
  }

  private static swScript(params: FirebaseFCM, mediaId: string): string {
    //TODO ver si es posible importar este script del src/app/shared/utils/service-worker.js
    return `
    'use strict';
    const pushOpen = '${PUSH_NOTIFICATION_STATISTIC_STATUS.OPENED}';

    self.addEventListener('notificationclick', function (event) {
      event.notification.close();
      insertPushPixel({eventAction: pushOpen, topic: event.notification.data.FCM_MSG.data.topic, attributes: {title: event.notification.title, click_action: event.notification.data.FCM_MSG.data.url}});
      event.waitUntil(
          clients.matchAll({type: 'window'}).then(windowClients => {
              for (const client in windowClients) {
                  const url = (event && event.notification && event.notification.data && event.notification.data.url) || '';
                  if (client.url === url && 'focus' in client) {
                      return client.focus();
                  }
              }
              if (clients.openWindow) {
                  const url =
                      (event &&
                          event.notification &&
                          event.notification.data.FCM_MSG &&
                          event.notification.data.FCM_MSG.data &&
                          event.notification.data.FCM_MSG.data.url) ||
                      '';
                  return clients.openWindow(url);
              }
          })
      );
    });


    importScripts('https://www.gstatic.com/firebasejs/7.9.1/firebase-app.js');
    importScripts('https://www.gstatic.com/firebasejs/7.9.1/firebase-messaging.js');

    firebase.initializeApp({
      apiKey: '${params.apiKey}',
      authDomain: '${params.authDomain}',
      databaseURL: '${params.databaseURL}',
      projectId: '${params.projectId}',
      storageBucket: '${params.storageBucket}',
      messagingSenderId: '${params.messagingSenderId}',
      appId: '${params.appId}',
      publicVapKey: '${params.publicVapKey}',
    });

    const pixelUrl = 'https://www.prometeo-media-service.com/assets/pixel.gif';

    function getDevice() {
        const UA = navigator.userAgent;
        if (UA.match(/iPad|Tablet/i)) {
            return '${PUSH_NOTIFICATION_STATISTIC_DEVICES.TABLET}';
        }
        if (UA.match(/Mobi/i)) {
            return '${PUSH_NOTIFICATION_STATISTIC_DEVICES.MOBILE}';
        }
        if (UA.match(/Windows NT|Macintosh|Linux/i)) {
            return '${PUSH_NOTIFICATION_STATISTIC_DEVICES.DESKTOP}';
        }
        return '${PUSH_NOTIFICATION_STATISTIC_DEVICES.OTHERS}';
    }

    function notificationAttributes (notification) {
        if (!notification) {
            return {title: '', url: '', body: '', icon: '', id: ''};
        }
        const title = notification.title || '';
        const url = notification.url || '';
        const id = notification.id || '';
        const body = notification.description || '';
        const icon = notification.icon || '';
        return {title, url, body, icon, id};
    };

    async function insertPushPixel ({eventAction, topic, attributes}) {
        const urlPixel = new URL(pixelUrl);
        if (attributes) {
          urlPixel.searchParams.set('event_id', attributes.id || "");
          urlPixel.searchParams.set('article_title', attributes.title || "");
          urlPixel.searchParams.set('event_url', attributes.click_action || "");
        }
        urlPixel.searchParams.set('event_type', 'push-event');
        urlPixel.searchParams.set('event_action', eventAction);
        urlPixel.searchParams.set('mid', '${mediaId}');
        urlPixel.searchParams.set('topic', topic);
        // TODO no es posible actualmente sacar m�s informaci�n del dispositivo en el service worker. Evaluar
        urlPixel.searchParams.set('pr_browser', '');
        urlPixel.searchParams.set('pr_browser_ver', '');
        urlPixel.searchParams.set('pr_os', '');
        urlPixel.searchParams.set('pr_device', getDevice() || '${PUSH_NOTIFICATION_STATISTIC_DEVICES.OTHERS}');
        await fetch(urlPixel);
    };

    const messaging = firebase.messaging();
    const pushReceived = '${PUSH_NOTIFICATION_STATISTIC_STATUS.RECEIVED}';
    const pushClosed = '${PUSH_NOTIFICATION_STATISTIC_STATUS.CLOSED}';


    self.addEventListener('push', function(event) {

    const payload = event.data ? event.data.json() : {};


    const options = {
      body: payload.notification.body,
      icon: payload.notification.icon,
      data: {
          topic: payload.notification.topic,
          url: payload.notification.click_action
      }

    };

      insertPushPixel({eventAction: pushReceived, topic: payload.notification.topic, attributes: payload.notification});

    // Display the notification
    event.waitUntil(self.registration.showNotification(payload.notification.title, options));
  });



    self.addEventListener('notificationclose', (evt) => {
      insertPushPixel({eventAction: pushClosed, topic: evt.notification.data.topic, attributes:{ title: evt.notification.title,  click_action: evt.notification.data.url }});
    });
    `;
  }

  public static downloadFile(params: FirebaseFCM, mediaId: string): void {
    download(this.swScript(params, mediaId), 'firebase-messaging-sw.js', 'text/javascript');
  }

  public static fileToBase64(file: File): Promise<string> {
    return new Promise((resolve) => {
      const reader = new FileReader();
      // Read file content on file loaded event
      reader.onload = function (event): void {
        const base64 = (event.target as any).result;
        resolve(base64);
      };

      // Convert data to base64
      reader.readAsDataURL(file);
    });
  }

  public static traverse(obj: any, prevVal: any, newVal: any) {
    for (const prop in obj) {
      if (typeof obj[prop] === 'object' && obj[prop] !== null) {
        Utils.traverse(obj[prop], prevVal, newVal);
      } else if (obj[prop] === prevVal) {
        obj[prop] = newVal;
      }
    }
  }

  public static transformNameSegment(name: string): string {
    const str = name.toUpperCase();
    const from = 'ÃÀÁÄÂÈÉËÊÌÍÏÎÒÓÖÔÙÚÜÛãàáäâèéëêìíïîòóöôùúüûÑñÇç ¡!¿?.,#~$%&()=-:;',
      to = 'AAAAAEEEEIIIIOOOOUUUUaaaaaeeeeiiiioooouuuunncc_';
    const mapping = {};
    for (let i = 0, j = from.length; i < j; i++) {
      mapping[from.charAt(i)] = to.charAt(i);
    }
    const ret = [];
    for (let i = 0, j = str.length; i < j; i++) {
      const c = str.charAt(i);
      if (mapping.hasOwnProperty(str.charAt(i))) {
        ret.push(mapping[c]);
      } else {
        ret.push(c);
      }
    }
    return ret.join('').toString();
  }

  public static comprobationRule(element: RuleModel): boolean {
    if (
      element.field == '' ||
      element.operator == '' ||
      element.value == '' ||
      (element.rules && !element.rules.length)
    ) {
      return true;
    } else {
      if (element.rules && element.rules.length) {
        return element.rules.some((rule) => Utils.comprobationRule(rule));
      } else {
        return false;
      }
    }
  }

  public static DeleteEmptyRules(values: string): string {
    return values ? values.replace(/(\s(and|or)\s[(][)])+/gm, '') : '';
  }

  public static GetConditionHistoricalSegments(values: string): string {
    return values
      ? values.replace(
          /((\s(AND|OR|and|or)\s)?user_dateCreate\s(=|!=|>|>=|<|<=)\s'[0-9]{4}-[0-9]{2}-[0-9]{2}')+/gm,
          "$& AND user_dateCreate != '' AND user_dateCreate IS NOT NULL"
        )
      : '';
  }

  public static DeleteConditionHistorialSegments(values: string): string {
    return values ? values.replace(/(\s(AND|OR|and|or)\s)?user_dateCreate\s(!=\s''|IS\sNOT\sNULL)+/gm, '') : '';
  }

  public static comprobationCopySegment(
    segmentHistoricalCopy: SegmentHistoricInterface,
    segmentHistoricalJson: {}
  ): boolean {
    const segmentHistoricalCopyJson = {};
    Object.entries(segmentHistoricalCopy).forEach(([key, value]) => {
      if (
        key == 'condition' ||
        key == 'typeSegment' ||
        key == 'opSegment' ||
        key == 'shared' ||
        key == 'viewsCondition' ||
        key == 'nViews' ||
        key == 'adManagerSegment' ||
        key == 'nViewsMax'
      ) {
        segmentHistoricalCopyJson[key] = value;
      } else if (key == 'dateInit' || key == 'dateEnd') {
        segmentHistoricalCopyJson[key] = value.format('YYYY-MM-DD');
      }
    });
    let diferentElement = false;
    for (const element in segmentHistoricalCopyJson) {
      if (segmentHistoricalCopyJson[element] != segmentHistoricalJson[element]) {
        diferentElement = true;
        break;
      }
    }
    return diferentElement;
  }
  public static comprobationCopyOperation(
    segmentOperationCopy: SegmentOperationsHistoricInterface,
    segmentOperationJson: {}
  ): boolean {
    const segmentOperationCopyJson = {};
    ['shared', 'operation', 'segments', 'adManagerSegment'].forEach((key) => {
      segmentOperationCopyJson[key] = segmentOperationCopy[key];
    });
    let diferentElement = false;
    for (const element in segmentOperationCopyJson) {
      if (segmentOperationCopyJson[element] != segmentOperationJson[element]) {
        diferentElement = true;
        break;
      }
    }
    return diferentElement;
  }

  public static comprobationCopyRealtimeSegment(
    segmentRealtimeCopy: SegmentRealTimeInterface,
    segmentRealtimeJson: {}
  ): boolean {
    const segmentRealtimeCopyJson = {};
    ['rule', 'days', 'adManager'].forEach((key) => {
      segmentRealtimeCopyJson[key] = segmentRealtimeCopy[key];
    });
    let diferentElement = false;
    for (const element in segmentRealtimeCopyJson) {
      if (segmentRealtimeCopyJson[element] != segmentRealtimeJson[element]) {
        diferentElement = true;
        break;
      }
    }
    return diferentElement;
  }

  public static deleteSpecialCharacter(name: string): string {
    const lowerCaseName = name.toLowerCase();
    return lowerCaseName.replace(/_/g, ' ');
  }

  public static hasExpired(date, timeToAdd?): boolean {
    if (!timeToAdd) timeToAdd = '00:00';

    const [hours, minutes] = timeToAdd.split(':');
    const targetDateTimeMillis = moment(date).add(hours, 'hours').add(minutes, 'minutes').toDate().getTime();
    return Date.now() > targetDateTimeMillis;
  }
  public static checkAndWarnActualParametersPermissions(
    queryBuilder: QueryBuilderComponent,
    rules: RuleModel
  ): boolean {
    const previousParameters: string[] = [];
    this.extractRules(rules, previousParameters);
    const parametersWithoutColumn = previousParameters.filter(
      (parameter) => !this.existsQueryBuilderColumnForParameter(queryBuilder, parameter)
    );
    if (parametersWithoutColumn.length) {
      Swal.fire({
        icon: 'warning',
        title: 'Error en permisos',
        html: `No se dispone de permisos para todos los parámetros existentes inicialmente en el segmento:
          <br><br>
          <b>${parametersWithoutColumn.join('<br>')}</b>
          <br><br>
          Si continúa, se perderá información relativa a esos parámetros.
          `,
      });
      return false;
    }
    return true;
  }

  public static extractRules(rules, parameters: string[]) {
    if (rules.condition) {
      return this.extractRules(rules.rules, parameters);
    }
    if (rules.length) {
      return rules.forEach((rule) => this.extractRules(rule, parameters));
    }
    return parameters.push(rules.field);
  }

  public static existsQueryBuilderColumnForParameter(queryBuilder: QueryBuilderComponent, parameter: string): boolean {
    return queryBuilder.columns.some((column) => column.field === parameter);
  }

  /**
   * Para añadir un control a un formulario. Comprueba si existe previamente.
   *
   * @param formGroup     Formulario al que añadir el nuevo control
   * @param controlName   Nombre del nuevo control
   * @param value         Valor del control, por defecto ''
   * @param validators    Array de funciones de validación, por defecto [Validators.required]
   * @returns             El control configurado
   */
  public static addFormControl(
    formGroup: UntypedFormGroup,
    controlName: string,
    value: any = '',
    validators: ValidatorFn[] = [Validators.required],
    disabled = false
  ): void {
    if (formGroup.contains(controlName)) return;
    formGroup.addControl(controlName, new UntypedFormControl({ value, disabled }, [...validators]));
  }

  public static removeFormControl(formGroup: UntypedFormGroup, controlName: string): void {
    if (!formGroup.contains(controlName)) return;
    formGroup.removeControl(controlName);
  }

  public static checkTriggerIsIntoActiveTestAb(element: any, activeTestsAbIds: string[]): boolean {
    const testAb = element.testABList.find((id) => activeTestsAbIds.includes(id));
    return !!testAb;
  }

  /**
   *
   * @deprecated The method should not be used, please change it to AlertService
   */
  public static notify(
    title: string,
    icon: NOTIFY_TYPE | SweetAlertIcon,
    html: string,
    confirmButtonText = 'Ok',
    cancelButtonText = 'Cancelar',
    showCancelButton = icon === ConstantMessages.WarningType
  ): Promise<SweetAlertResult> {
    return Swal.fire({
      title,
      icon,
      html,
      confirmButtonText,
      cancelButtonText,
      showCancelButton: showCancelButton,
      focusCancel: true,
    });
  }

  public static isArray(obj: any) {
    return Array.isArray(obj);
  }

  public static getArticleByType(type: string): boolean {
    return (
      type === ConstantsStatistics.segmentedDatasourceType ||
      type === ConstantsStatistics.datasourceType ||
      type === ConstantsStatistics.newsletterType ||
      type === ConstantsStatistics.pushNType
    );
  }

  public static formatStatisticsGraphicValues(val: number, series: any): string {
    return val.toLocaleString('de-DE').includes(',') && isNaN(series)
      ? val.toLocaleString('de-DE') + ' %'
      : val.toLocaleString('de-DE');
  }

  public static getHtmlLocalDateTime(date: Date): string {
    if (!date || isNaN(date.getTime())) return '';
    const timezoned = new Date(date.getTime());
    return this.alterTimeZone(timezoned).toISOString().replace('Z', '');
  }

  public static getDateFromDateOrString(date: string | Date) {
    if (typeof date === 'string') {
      date = new Date(date + 'Z');
      return this.alterTimeZone(date, false);
    }
    return this.alterTimeZone(date);
  }

  public static alterTimeZone(date: Date, add: boolean = true): Date {
    return add
      ? new Date(date.setMinutes(date.getMinutes() - date.getTimezoneOffset()))
      : new Date(date.setMinutes(date.getMinutes() + date.getTimezoneOffset()));
  }

  public static getMediaBySiteId(medias: MediaInterface[], siteId: string): MediaInterface[] {
    return medias.filter((media: MediaInterface): boolean =>
      media.sites.some((site: SiteInterface): boolean => site.id === siteId)
    );
  }

  public static getSiteNameById(medias: MediaInterface[], siteId: string): string {
    if (!medias) return '';
    for (const media of medias) {
      for (const site of media.sites) if (site.id === siteId) return site.name;
    }
    return '';
  }

  public static getAllAvailableSitesIdsFromMedias(
    medias: MediaInterface[],
    sites: SiteInterface[],
    siteInterface: SiteInterface
  ): string[] {
    const sitesIds: string[] = [];
    medias.forEach((media: MediaInterface) =>
      media.sites.forEach((site: SiteInterface) => {
        const completeSite = sites.find((siteComplete) => siteComplete.id === site.id);
        if (
          completeSite &&
          completeSite.newslettersCredentials &&
          completeSite.newslettersCredentials.apiKey &&
          siteInterface.newslettersCredentials &&
          siteInterface.newslettersCredentials.apiKey &&
          completeSite.newslettersCredentials.apiKey === siteInterface.newslettersCredentials.apiKey
        )
          sitesIds.push(site.id);
      })
    );

    return sitesIds;
  }

  public static isTransparentBodyColumn(trigger: TriggerInterface | PushSubscriptionInterface | NewslettersSubscriptionInterface) {
    const parser = new DOMParser();
    const doc1 = parser.parseFromString(trigger.design.html, 'text/html');
    let hasTransparentColumn,
      hasTransparentBody = false;

    const element = doc1.head.getElementsByTagName('style');
    if (element && element[0] && element[0].childNodes && element[0].childNodes.length) {
      if (
        element[0].childNodes[0].textContent.includes('background-color: transparent') ||
        element[0].childNodes[0].textContent.includes('background-color: #e7e7e7')
      )
        hasTransparentBody = true;
    }

    for (let i = 1; i <= 20 && !hasTransparentColumn; i = i + 1) {
      const element = doc1.getElementById(`u_row_${i}`);
      if (
        element &&
        element.style &&
        (element.style.backgroundColor === '' || element.style.backgroundColor === 'transparent')
      ) {
        hasTransparentColumn = true;
      }
    }

    return hasTransparentColumn && hasTransparentBody;
  }

  public static convertParams(data: any) {
    let params = new HttpParams();
    Object.entries(data).forEach(([key, value]: [string, any]) => {
      params = params.set(key, value);
    });
    return params;
  }

  public static getValueFromBufferString(data: string, encoding: BufferEncoding = 'base64', resultEncoding: BufferEncoding = 'utf-8'): string {
    return Buffer.from(data, encoding).toString(resultEncoding);
  }

  public static getComponentListAsString(
    components: { id: string; active: boolean; type: string }[],
    typeString: string
  ): string {
    return components
      .filter((component) => component.type === typeString)
      .map((component) => component.id)
      .join(', ');
  }

  public static deleteSegmentWarningText(
    components: { id: string; active: boolean; type: string }[],
    htmlText: string
  ): string {
    const widgets = this.getComponentListAsString(components, CONSTANTS.PROMETEO_WIDGET_ENTITY_TYPE_LITERAL);
    const datasources = this.getComponentListAsString(components, CONSTANTS.PROMETEO_DATASOURCE_ENTITY_TYPE_LITERAL);
    const segmentedDatasources = this.getComponentListAsString(
      components,
      CONSTANTS.PROMETEO_SEGMENTED_DATASOURCE_ENTITY_TYPE_LITERAL
    );
    const triggers = this.getComponentListAsString(components, CONSTANTS.PROMETEO_TRIGGER_ENTITY_TYPE_LITERAL);
    const pushSubscriptions = this.getComponentListAsString(
      components,
      CONSTANTS.PUSH_SUBSCRIPTION_ENTITY_TYPE_LITERAL
    );
    const newslettersSubscriptions = this.getComponentListAsString(
      components,
      CONSTANTS.NEWSLETTER_ENTITY_TYPE_LITERAL
    );
    const testsAB = this.getComponentListAsString(components, CONSTANTS.PROMETEO_TEST_AB_ENTITY_TYPE_LITERAL);
    const testsABWidgets = this.getComponentListAsString(
      components,
      CONSTANTS.PROMETEO_TEST_AB_WIDGETS_ENTITY_TYPE_LITERAL
    );

    if (widgets) htmlText += `<u>Widgets:</u> ${widgets}<br>`;
    if (datasources) htmlText += `<u>Fuentes de datos:</u> ${datasources}<br>`;
    if (segmentedDatasources) htmlText += `<u>Fuentes de datos segmentadas:</u> ${segmentedDatasources}<br>`;
    if (triggers) htmlText += `<u>Activadores:</u> ${triggers}<br>`;
    if (pushSubscriptions) htmlText += `<u>Notificaciones Push:</u> ${pushSubscriptions}<br>`;
    if (newslettersSubscriptions) htmlText += `<u>Newsletters:</u> ${newslettersSubscriptions}<br>`;
    if (testsAB) htmlText += `<u>Tests A/B de Activadores:</u> ${testsAB}<br>`;
    if (testsABWidgets) htmlText += `<u>Tests A/B de Widgets:</u> ${testsABWidgets}<br>`;

    return htmlText;
  }

  public static async showDeleteSegmentWarning(
    components: { id: string; active: boolean; type: string }[],
    segmentService: SegmentService,
    mediaId: string,
    infoTitle: string,
    infoHtml: string,
    icon: SweetAlertIcon
  ): Promise<void> {
    let htmlText = `El segmento está siendo utilizado en los siguientes componentes:<br>`;
    htmlText = this.deleteSegmentWarningText(components, htmlText);
    const operationsSegment = this.getComponentListAsString(
      components,
      CONSTANTS.PROMETEO_OPERATION_SEGMENT_ENTITY_TYPE_LITERAL
    );
    if (operationsSegment) {
      htmlText += `<br><u>Operaciones Históricas:</u>`;
      for (const operationSegment of components.filter(
        (component) => component.type === CONSTANTS.PROMETEO_OPERATION_SEGMENT_ENTITY_TYPE_LITERAL
      )) {
        const componentsUsingOperationalSegment = await segmentService
          .componentsUsingSegment(mediaId, operationSegment.id)
          .pipe(first())
          .toPromise();

        if (componentsUsingOperationalSegment && componentsUsingOperationalSegment.length)
          htmlText += `<br>La operación histórica ${operationSegment.id} que está siendo utilizada en los siguientes componentes:<br>`;
        else htmlText += `<br>La operación histórica ${operationSegment.id} que no la utiliza ningún componente.<br>`;
        htmlText = this.deleteSegmentWarningText(componentsUsingOperationalSegment, htmlText);
      }
    }
    htmlText += infoHtml;

    Swal.fire({
      title: infoTitle,
      html: htmlText,
      icon,
      width: '720px',
      confirmButtonText: 'OK',
      showCancelButton: false,
      showConfirmButton: true,
    });
  }

  public static showLoadingAlert(title: string, html: string) {
    Swal.fire({
      title,
      html,
      allowOutsideClick: false,
      willOpen: () => Swal.showLoading(),
      showConfirmButton: false,
    } as any);
  }

  public static formatSiteName(site: SiteInterface): string {
    return site.name.replace('https://', '').replace('www.', '').replace('/', '');
  }

  public static isObject = (data: unknown) => typeof data === 'object' && data !== null && !Array.isArray(data);

  public static isDate = (data: any) => {
    if(!data) return false;
    return typeof data.getMonth === 'function';
  };

  public static siftSeconds = (date: Date): Date => {
    const copy = new Date(date);
    copy.setSeconds(0, 0);
    return copy;
  };

  private static getUtmValue(utm: Utms): string {
    return utm && utm.utm_campaign ? utm.utm_campaign.content || utm.utm_campaign.customValue : '';
  }

  private static getUtmsQueryParamsString(utm: Utms): string {
    const utmCampaignValue = this.getUtmValue(utm);
    const source = utm && utm.utm_source ? utm.utm_source.content : '';
    const medium = utm && utm.utm_medium ? utm.utm_medium.content : '';
    return `utm_source=${source}&utm_medium=${medium}&utm_campaign=${utmCampaignValue}`;
  }

  public static updateUtms(url: string, initialUtms: Utms, queryParamUtms: string, utmString = 'utm_source'): string {
    const questionMarkUtmString = `?${utmString}`;
    const ampersandUtmString = `&${utmString}`;
    const utmsQueryParams = this.getUtmsQueryParamsString(initialUtms);

    if (url.includes(questionMarkUtmString)) {
      queryParamUtms = queryParamUtms.replace(ampersandUtmString, questionMarkUtmString);
      return initialUtms ? url.replace(`?${utmsQueryParams}`, queryParamUtms) : url;
    }

    queryParamUtms = queryParamUtms.replace(questionMarkUtmString, ampersandUtmString);
    return url.replace(`&${utmsQueryParams}`, queryParamUtms);
  }

  public static deleteUtms(url: string, initialUtms: Utms): string {
    if (!initialUtms) return url;

    const utmsQueryParams = this.getUtmsQueryParamsString(initialUtms);
    return url.replace(`?${utmsQueryParams}`, '').replace(`&${utmsQueryParams}`, '');
  }

  // It transforms a date string in format 'dd/MM/yy' into a date string 'yy/MM/dd' or 'dd/MM/yy, HH:mm' into 'yy/MM/dd, HH:mm' so it can be compared.
  public static reformatDateString(dateString: string): string {
    const dd = dateString.substring(0, 2);
    const mm = dateString.substring(3, 5);
    const yy = dateString.substring(6, 8);
    const hour = dateString.substring(8, dateString.length);

    return `${yy}/${mm}/${dd}${hour}`;
  }

  public static sortExcelStatistics(metricsDTO: any[], firstSortAttribute: string, secondSortAttribute: string): any[] {
    return metricsDTO.sort((a, b) => {
      if (this.reformatDateString(a[firstSortAttribute]) > this.reformatDateString(b[firstSortAttribute])) return 1;
      if (this.reformatDateString(a[firstSortAttribute]) < this.reformatDateString(b[firstSortAttribute])) return -1;
      if (Number(b[secondSortAttribute].replace(/\./g, '')) > Number(a[secondSortAttribute].replace(/\./g, '')))
        return 1;
      if (Number(b[secondSortAttribute].replace(/\./g, '')) < Number(a[secondSortAttribute].replace(/\./g, '')))
        return -1;
    });
  }

  public static updateUnactiveComponentsUsingSegment(
    components: { id: string; active: boolean; type: string }[],
    mediaId: string,
    segmentId: string,
    services: AllServicesInterface
  ): Observable<any[]> {
    const observableList: Observable<any>[] = [];
    for (const component of components) {
      switch (component.type) {
        case ConstantsSegments.AvailableComponentsType.triggerType:
          observableList.push(
            services.triggerService.get(component.id).pipe(
              first(),
              switchMap((trigger) => {
                trigger.segments = trigger.segments.filter((segment) => segment !== segmentId);
                return services.triggerService.update(component.id, trigger);
              })
            )
          );
          break;

        case ConstantsSegments.AvailableComponentsType.testABType:
          observableList.push(
            services.testABService.getById(component.id).pipe(
              first(),
              switchMap((testAB) => {
                testAB.segments = testAB.segments.filter((segment) => segment !== segmentId);
                return services.testABService.update(component.id, testAB);
              })
            )
          );
          break;

        case ConstantsSegments.AvailableComponentsType.widgetType:
          observableList.push(
            services.widgetService.get(component.id).pipe(
              first(),
              switchMap((widget) => {
                widget.segments = widget.segments.filter((segment) => segment !== segmentId);
                return services.widgetService.update(component.id, widget);
              })
            )
          );
          break;

        case ConstantsSegments.AvailableComponentsType.pushSubscriptionType:
          observableList.push(
            services.pushSubscriptionService.get(component.id).pipe(
              first(),
              switchMap((pushSubscription) => {
                pushSubscription.segments = pushSubscription.segments.filter((segment) => segment !== segmentId);
                return services.pushSubscriptionService.update(component.id, pushSubscription);
              })
            )
          );
          break;

        case ConstantsSegments.AvailableComponentsType.newsletterSubscriptionType:
          observableList.push(
            services.newsletterSubscriptionService.getById(component.id).pipe(
              first(),
              switchMap((newsletterSubscription) => {
                newsletterSubscription.segments = newsletterSubscription.segments.filter(
                  (segment) => segment !== segmentId
                );
                return services.newsletterSubscriptionService.update(component.id, newsletterSubscription);
              })
            )
          );
          break;

        case ConstantsSegments.AvailableComponentsType.historicalOperationType:
          observableList.push(
            services.segmentService.componentsUsingSegment(mediaId, component.id).pipe(
              first(),
              switchMap((historicalOperationComponents) => {
                return this.updateUnactiveComponentsUsingSegment(
                  historicalOperationComponents,
                  mediaId,
                  component.id,
                  services
                );
              })
            )
          );
          break;

        case ConstantsSegments.AvailableComponentsType.testABWidgetsType:
          observableList.push(
            services.testABWidgetsService.getById(component.id).pipe(
              first(),
              switchMap((testABWidgets) => {
                testABWidgets.segments = testABWidgets.segments.filter((segment) => segment !== segmentId);
                return services.testABWidgetsService.update(component.id, testABWidgets);
              })
            )
          );
          break;

        case ConstantsSegments.AvailableComponentsType.datasourceType:
          observableList.push(
            services.datasourceService.get(component.id).pipe(
              first(),
              switchMap((datasource) => {
                datasource.segments = datasource.segments.filter((segment) => segment !== segmentId);
                return services.datasourceService.update(component.id, datasource);
              })
            )
          );
          break;

        case ConstantsSegments.AvailableComponentsType.segmentedDatasourceType:
          observableList.push(
            services.segmentedDatasourceService.get(component.id).pipe(
              first(),
              switchMap((segmentedDatasource) => {
                if (segmentedDatasource.datasources) {
                  segmentedDatasource.datasources.forEach((datasource) => {
                    datasource.segments = datasource.segments.filter((segment) => segment !== segmentId);
                  });
                  return services.segmentedDatasourceService.update(component.id, segmentedDatasource).toPromise();
                }
                return of(undefined);
              })
            )
          );
          break;
      }
    }
    if(!components.length) return of(null);
    return combineLatest(observableList);
  }

  //TODO Extract to imageUtils
  public static isValidImage(imageFile: File, accept = 'image/*'): boolean {
    return imageFile && imageFile.type.match(new RegExp(accept)) && imageFile.size <= CONSTANTS.MAX_FILE_SIZE;
  }

  public static async processImage(imageFile: File): Promise<File> {
    let newBlob = new Blob([imageFile], { type: imageFile.type });
    if (imageFile && !Utils.isValidImage(imageFile)) {
      newBlob = await Utils.compressImageToSize(imageFile);
    }
    return Utils.renameImageToUnique(imageFile, newBlob);
  }

  private static newUniqueValidName(fileName: string): string {
    const fileNameParts = fileName.replace(/\s+/g, '-').split('.');
    const extension = fileNameParts.length > 1 ? `.${fileNameParts[fileNameParts.length - 1]}` : '';
    return `push-image-${Date.now()}${extension}`;
  }

  private static renameImageToUnique(imageFile: File, newBlob: Blob): File {
    return new File([newBlob], Utils.newUniqueValidName(imageFile.name), {
      type: imageFile.type,
    });
  }

  private static compressImageToSize(imageFile: File, desiredSize = CONSTANTS.MAX_FILE_SIZE): Promise<Blob> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (): void => {
        if (!(reader.result && typeof reader.result === 'string')) reject(new Error("No se pudo cargar la imagen."));
        const img = new Image();
        img.src = URL.createObjectURL(imageFile);
        img.onload = function (): void {
          const canvas = Utils.getCanvas(img);
          const quality = Utils.getCompressionQuality(canvas, imageFile.type, desiredSize);
          canvas.toBlob(
            (blob) => {
              resolve(blob);
            },
            imageFile.type,
            quality
          );
        };
      };
      reader.onerror = (): void => {
        reject(new Error("Error al leer el archivo."));
      };
      reader.readAsDataURL(imageFile);
    });
  }

  private static getCompressionQuality(canvas: HTMLCanvasElement, fileType: string, desiredSize: number): number {
    let quality = 1.0;
    let currentSize: number;
    do {
      quality = parseFloat((quality - 0.1).toFixed(2));
      currentSize = canvas.toDataURL(fileType, quality).length;
    } while (currentSize > desiredSize && quality > 0.1);
    return quality;
  }

  private static getCanvas(img: HTMLImageElement): HTMLCanvasElement {
    const canvas = document.createElement("canvas");
    canvas.width = img.width;
    canvas.height = img.height;
    const ctx = canvas.getContext("2d");
    ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
    return canvas;
  }

  public static calculatePercent(nUsers: number, nTotal: number): number {
    return Math.round(((nUsers * 100) / nTotal) * 100) / 100 || 0;
  }

  public static isStatic(typeSegment: string): boolean {
    return typeSegment === 's';
  }

  public static removePartOfJavascript(javascript: string, id: string): string{
    const showWithId = CONSTANTS.SHOW_EVENT.replace('(', `('${id}'`);
    if(javascript.includes(showWithId))return javascript.replace(showWithId, '');
    return javascript.replace(CONSTANTS.SHOW_EVENT, '');
  }

  public static isBoolean(value: string | boolean): boolean {
    if (typeof value === 'boolean') return value;
    return value === 'true';
  }

  public static getStatisticsMethodsName(method: {name: string}): string {
    const methodMappings = {
      [ConstantsStatistics.METRICS_SHOW]: ConstantsStatistics.METRICS_SHOW_CARD,
      [ConstantsStatistics.METRICS_CLOSE]: ConstantsStatistics.METRICS_CLOSE_CARD,
      [ConstantsStatistics.METRICS_ACTIVATE]: ConstantsStatistics.METRICS_ACTIVATE_CARD,
      [ConstantsStatistics.METRICS_ACTIVATE1]: ConstantsStatistics.METRICS_ACTIVATE1_CARD,
      [ConstantsStatistics.METRICS_ACTIVATE2]: ConstantsStatistics.METRICS_ACTIVATE2_CARD,
    };

    return methodMappings[method.name] || 'Botón ' + method.name;
  }

}

export const getDescendantControl = (keyPath: string[], formGroup: UntypedFormGroup): AbstractControl =>
   keyPath.reduce((currentControl, pathFragment) => {
    const nextControl = currentControl.get(pathFragment);
    if (nextControl === null) {
      throw new Error(`Control with path ${pathFragment} not found`);
    }
    return nextControl;
  }, formGroup);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function deepConvertDates(obj: any): any {
  if (!obj || typeof obj !== 'object' || obj instanceof Date) return obj;

  if (obj instanceof firebase.firestore.Timestamp || moment.isMoment(obj)) {
    return obj.toDate();
  }

  if (Array.isArray(obj)) {
    return obj.map(item => deepConvertDates(item));
  }

  const newObj = {};
  for (const key in obj) {
    if (key in obj) {
      newObj[key] = deepConvertDates(obj[key]);
    }
  }
  return newObj;
}

export function trackById(index: number, item: {id: string | number}): string | number {
  return item.id;
}

export function areDuplicatedElementsInArray<T extends string | number | boolean>(array: T[]): boolean {
  const uniqueSet = new Set(array);
  return uniqueSet.size !== array.length;
};

export function toHttpParams(params: Record<string, any>): HttpParams | undefined {
  if (!params) return;
  return Object.entries(params).reduce(
    (prev, [key, value]) => prev.set(key, typeof value === 'object' ? JSON.stringify(value) : value),
    new HttpParams()
  );
}

export function getEnvironment(): string {
  return environment.env === 'local' ? 'dev' : environment.env;
}

export function getActionsByPermissions(
  requiredPermissions: Record<string, string[]>,
  userPermissions: string[],
  actions?: {
    [key: string]: ActionInterface;
  }
): ActionInterface[] {
  return Object.entries(requiredPermissions).reduce((result, [action, permissions]) => {
    if (isSubset(permissions, userPermissions)) {
      const actionToPush = Object.values(actions).find(({ id }) => id === Number(action));
      if (actionToPush) result.push(actionToPush);
    }
    return result;
  }, [] as ActionInterface[]);
}

export function isSubset<T>(subsetArray: T[], mainArray: T[], key?: keyof T): boolean {
  const mainSet = key ? new Set(mainArray.map((item) => item[key])) : new Set(mainArray);
  // @ts-ignore
  return subsetArray.every((item) => mainSet.has(key ? item[key] : item));
}

export function groupBy<T>(array: T[], key: (item: T) => ObjectKey): Record<ObjectKey, T[]> {
  return array.reduce(
    (groups, item) => ({
      ...groups,
      [key(item)] : [...(groups?.[key(item)] ?? []), item],
    }),
    {} as Record<ObjectKey, T[]>
  );
}
