import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { startWith, takeUntil } from 'rxjs/operators';

interface Feature {
  title: string;
  innerHtml: string;
  tag: string;
}

@Component({
  selector: 'app-html-editor',
  templateUrl: './html-editor.component.html',
  styleUrls: ['./html-editor.component.scss'],
})
export class HtmlEditorComponent implements OnInit, OnDestroy {
  @Input() control: UntypedFormControl;
  @Input() title: string;

  @ViewChild('divResizable', {
    static: true,
  })
  divResizable: ElementRef;

  @ViewChild('divPreview', { static: true }) divPreview: ElementRef;

  DRAGGABLE_WIDTH = 14;
  maxWidth: number;
  sumWidth: number;
  cursor: { selectionStart: number; selectionEnd: number };

  readonly features: Feature[] = [
    { title: 'Añadir título de tamaño 1', innerHtml: 'Título 1', tag: 'h1' },
    { title: 'Añadir título de tamaño 2', innerHtml: 'Título 2', tag: 'h2' },
    { title: 'Añadir título de tamaño 3', innerHtml: 'Título 3', tag: 'h3' },
    { title: 'Añadir título de tamaño 4', innerHtml: 'Título 4', tag: 'h4' },
    { title: 'Añadir título de tamaño 5', innerHtml: 'Título 5', tag: 'h5' },
    { title: 'Añadir título de tamaño 6', innerHtml: 'Título 6', tag: 'h6' },
    { title: 'Añadir párrafo', innerHtml: 'Párrafo', tag: 'p' },
    { title: 'Añadir negrita', innerHtml: '<strong>B</strong>', tag: 'strong' },
    { title: 'Añadir cursiva', innerHtml: '<i>i</i>', tag: 'i' },
    { title: 'Añadir subrayado', innerHtml: '<span style="text-decoration: underline;">u</span>', tag: 'underlined' },
    { title: 'Añadir contenedor', innerHtml: 'Span', tag: 'span' },
    { title: 'Añadir enlace', innerHtml: `<i class='material-icons'>add_link</i>`, tag: 'a' },
    { title: 'Añadir imagen', innerHtml: `<i class='material-icons'>image</i>`, tag: 'img' },
    { title: 'Añadir listado', innerHtml: `<i class='material-icons'>list_alt</i>`, tag: 'ul' },
    { title: 'Añadir elemento a la lista', innerHtml: `<i class='material-icons'>playlist_add</i>`, tag: 'li' },
  ];

  private readonly unsubscribe = new Subject<void>();

  ngOnInit(): void {
    const element = document.getElementById('preview-card');

    this.maxWidth = element?.offsetWidth - this.DRAGGABLE_WIDTH;
    this.sumWidth = this.maxWidth;

    this.control.valueChanges.pipe(startWith(this.control.value), takeUntil(this.unsubscribe)).subscribe((value) => {
      this.divPreview.nativeElement.innerHTML = value;
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  inputTag(type: string): void {
    const initialValue = this.control.value || '';
    const selectedText = document.getSelection().toString();

    const tagMap = {
      underlined: `<span style="text-decoration: underline;">${selectedText || ''}</span>`,
      a: `<a href="">${selectedText || ''}</a>`,
      img: `<img src="${selectedText || ''}" alt="" />`,
      ul: `<ul>\n<li>${selectedText || ''}</li>\n</ul>`,
    };

    if (selectedText) {
      const newValue = tagMap[type]
        ? this.replaceSelectionOnCursor(initialValue, tagMap[type])
        : this.replaceSelectionOnCursor(initialValue, `<${type}>${selectedText}</${type}>`);
      this.control.patchValue(newValue);
    } else {
      const emptyTag = tagMap[type] || `<${type}></${type}>`;
      this.control.patchValue(initialValue + emptyTag);
    }
  }

  startResize(event: MouseEvent): void {
    const initialX = event.clientX;
    const initialWidth = this.divResizable.nativeElement.offsetWidth;

    const moving = (e: MouseEvent): void => {
      const newX = e.clientX - initialX;
      if (initialWidth + newX > this.maxWidth) return;
      this.sumWidth = initialWidth + newX;
      this.divResizable.nativeElement.style.width = `${initialWidth + newX}px`;
    };

    const stopMovement = (): void => {
      document.removeEventListener('mousemove', moving);
      document.removeEventListener('mouseup', stopMovement);
    };

    document.addEventListener('mousemove', moving);
    document.addEventListener('mouseup', stopMovement);
  }

  storeCursor(event: FocusEvent): void {
    if (!('tagName' in event.target) || (event.target as HTMLTextAreaElement).tagName !== 'TEXTAREA') {
      throw new Error('The target element must be a textarea');
    }

    const { selectionStart, selectionEnd } = event.target as HTMLTextAreaElement;
    this.cursor = { selectionStart, selectionEnd };
  }

  trackByFeatureTag(index: number, feature: Feature): string {
    return feature.tag;
  }

  private replaceSelectionOnCursor(value: string, replacement: string): string {
    return value.substring(0, this.cursor.selectionStart) + replacement + value.substring(this.cursor.selectionEnd);
  }
}
