export const MILLISECONDS_ON_ONE_SECOND = 1_000;
export const days = (quantity: number): number => quantity * hours(24);
export const hours = (quantity: number): number => quantity * minutes(60);
export const minutes = (quantity: number): number => quantity * seconds(60);
export const seconds = (quantity: number): number => quantity * MILLISECONDS_ON_ONE_SECOND;

export const PAGINATE_SIZE_RESTRICTED_OPTIONS = [10];

export enum ROUTER_LINK {
  CONTENT = 'content',
  PROMPTS = 'prompts',
  MODELS = 'models',
  CONTENT_GENERATION = 'content-generation', // MODELS
  TRANSCRIPTS = 'transcripts',
}

export const ACTIONS: { [key: string]: { id: number; name: string } } = {
  // TODO migrar los id a Symbol para evitar colisiones y facilitar el mantenimiento.
  INSPECT: { id: 1, name: 'Ver detalles' },
  CREATE: { id: 2, name: 'Crear' },
  UPDATE: { id: 3, name: 'Editar' },
  COPY: { id: 4, name: 'Copiar' },
  DELETE: { id: 5, name: 'Eliminar' },
};

export enum ENTRYPOINTS {
  CREATE = 'create',
  EDIT = 'edit',
  INSPECT = 'inspect',
  COPY = 'copy',
  FORM = 'form',
  DELETE = 'delete',
  BASE = 'thalia',
  PROMPTS = 'prompts',
  CONTENT = 'content',
  TRANSCRIPTS = 'transcripts',
}

export const ENDPOINTS = {
  CONTENT: {
    CONTENT: '/Historico',
    CONTENT_BY_ID: (id: string) => `/Contenido/${id}`,
    GET_DATA_BY_URL: (url: string) => `/Contenido/LdJson?url=${url}`,
    CONTENT_VERSIONS_BY_CONTENT_ID: (id: string) => `/Contenido/${id}/Versiones`,
    CREATE: '/Contenido',
    CREATE_VERSION: '/Contenido/GenerarVersion',
    SAVE_PUBLISHED_URL: '/Contenido/GuardarUrlPublicada',
    SAVE_DEFINITIVE_VERSION: (contentId: string, versionId: string) =>
      `/Contenido/${contentId}/Versiones/${versionId}/Definitiva`,
    COMPARE_TEXTS: '/Contenido/CompararTextos',
    MULTIPLES_TITLES: '/Contenido/GenerarNTitulos',
    UPDATE_TITLE: (contentId: string) => `/Contenido/${contentId}/Versiones`,
    DELETE_CONTENT: (id: number) => `/Contenido/${id}`,
    MODELS: {
      BASE: '/Contenido/Modelos',
      GET_ALL_MODELS: '/Contenido/ModelosHijos',
      BY_ID: (id: string) => `/Contenido/Modelos/${id}`,
    },
  },
  MANAGEMENT: {
    PROMPTS: '/Gestion/Prompts', // POST
    PROMPT_BY_ID: (id: number) => `/Gestion/Prompt/${id}`,
    VERSIONS_BY_PROMPT_ID: (id: number) => `/Gestion/Prompt/${id}/Versiones`,
    VERSION_BY_ID: (id: number) => `/Gestion/Version/${id}`, // GET DELETE
    INSERT_VERSION: '/Gestion/InsertarVersion', // POST
  },
  TRANSCRIPTS: {
    LIST: '/Transcripcion',
    GET_FILE_TYPES: '/Transcripcion/Mediatypes',
    INSERT_TRANSCRIPT_CONTENT: '/Transcripcion/Contenido/Insertar',
    GET_TRANSCRIPT_CONTENT: '/Transcripcion/Contenido',
    DELETE_TRANSCRIPT: (id: number) => `/Transcripcion/${id}`,
    TRANSCRIPT_BY_ID: (id: string) => `/Transcripcion/${id}`,
    MODELS: {
      LIST: (transcriptId: string) => `/Transcripcion/${transcriptId}/Modelos`,
      GET_BY_ID: (id: string) => `/Transcripcion/Modelos/${id}`,
      INSERT: '/Transcripcion/Modelos/Insertar',
      UPDATE: '/Transcripcion/Modelos/Actualizar',
    },
  },
};

export const DEFAULT_PROMPT_RESPONSE =
  'Genera un JSON que contenga un listado de noticias. Cada elemento del listado debe tener las claves "titular", "subtitular" y "cuerpo". Aquí tienes un ejemplo del formato esperado: [{"titular":"titulo de noticia 1","subtitular":"subtitular de la noticia 1","cuerpo":"cuerpo completo de la noticia 1"},{"titular":"titulo de noticia 2","subtitular":"subtitular de la noticia 2","cuerpo":"cuerpo completo de la noticia 2"}]';
export const CREATE_ID = 0;
export const HERALDO_ID = '001';
export const ASYNC_MIN_MB_DEFAULT = 0;
export const WORKER_CHUNK_SIZE = 1024 * 1024; // Tamaño del chunk en bytes (1MB)
