import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import firebase from 'firebase/compat/app';

@Component({
    selector: 'app-form-trigger-app',
    templateUrl: './form-trigger-app.component.html',
    styleUrls: ['./form-trigger-app.component.scss'],
})
export class FormTriggerAppComponent implements OnInit {
    @Input() public form2: UntypedFormGroup;
    @Input() public form3: UntypedFormGroup;
    @Input() public isModalFS: boolean;
    @Input() public isModalSimple: boolean;
    @Input() public isBox: boolean;
    @Input() public isEditMode: boolean;
    @Output() private deleteActionEvent: EventEmitter<{ field: string; fieldAction: string }> = new EventEmitter();
    @Output() private configActionFieldEvent: EventEmitter<{ field: string; fieldType: string }> = new EventEmitter();
    @Output() private changeDisableEvent: EventEmitter<string> = new EventEmitter();

    public fileToUpload: File | null = null;

    constructor(private readonly httpClient: HttpClient) {}

    ngOnInit() {}

    public deleteAction(field: string, fieldAction: string) {
        this.deleteActionEvent.emit({ field, fieldAction });
    }

    public configActionField(field: string, fieldType: string) {
        this.configActionFieldEvent.emit({ field, fieldType });
    }

    public changeDisable(field: string) {
        this.changeDisableEvent.emit(field);
    }

    public handleFileInput(files: FileList) {
        this.fileToUpload = files.item(0);
        this.form2.get('imageName').setValue(this.fileToUpload.name);
        const storage = firebase
            .storage()
            .ref()
            .child('/triggerImages/' + this.fileToUpload.name);
        const upload = storage.put(this.fileToUpload);
        upload.then(() => storage.getDownloadURL()).then(url => this.form2.get('image').setValue(url));
    }

    public handleLogoInput(files: FileList) {
        this.fileToUpload = files.item(0);
        const storage = firebase
            .storage()
            .ref()
            .child('/triggerLogo/' + this.fileToUpload.name);
        const upload = storage.put(this.fileToUpload);
        upload.then(() => storage.getDownloadURL()).then(url => this.form2.get('logo').setValue(url));
    }
}
