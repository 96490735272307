import { Component, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { recursiveErrors, RecursiveErrorsNode } from '../../utils/utils-recursive-errors';

@Component({
  selector: 'app-error-list',
  templateUrl: './error-list.component.html',
  styleUrls: ['./error-list.component.scss']
})
export class ErrorListComponent {
  @Input() control: AbstractControl;
  @Input() isErrorPanelVisible = true;

  get errors(): RecursiveErrorsNode {
    return recursiveErrors(this.control);
  }
}
