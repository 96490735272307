import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { routes } from './app.routes';
import { CoreModule } from './core/core.module';
import { errorHandlerProvider } from './core/providers/error-handler.provider';
import { DatasourcesTypeToStringPipe } from 'src/app/shared/pipes/datasources-type-to-string.pipe';
import { TriggersTypeToStringPipe } from 'src/app/shared/pipes/triggers-type-to-string.pipe';
import { register } from 'swiper/element/bundle';

register(); // Init Swiper

@NgModule({
  declarations: [AppComponent],
  imports: [CoreModule, RouterModule.forRoot(routes)],
  providers: [
    errorHandlerProvider,
    DatasourcesTypeToStringPipe,
    TriggersTypeToStringPipe,
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
