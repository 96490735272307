import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import {
  PUSH_NOTIFICATION_STATISTIC_STATUS,
  PUSH_NOTIFICATION_STATISTIC_STATUS_TITLE,
} from 'src/app/components/push-notifications/web/scheduled/config/constants';
import {
  StatisticsPushNotificationInterface,
  PushNotificationTopContentTableDataInterface,
  PUSH_NOTIFICATION_COLUMN_NAME,
  PUSH_NOTIFICATION_TOP_CONTENT_COLUMNS,
} from 'src/app/core/interfaces/statistics/statistics-push-notification.interface';
import { SubGroupType } from 'src/app/core/types/sub-group.type';
import { TableColumn } from '../../generic-table/column.interface';
import { UntypedFormGroup } from '@angular/forms';
import { createFormGroup } from 'src/app/shared/utils/form-utils';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-statistic-push-notification-top-content',
  templateUrl: './statistic-push-notification-top-content.component.html',
})
export class StatisticsPushNotificationTopContentComponent implements OnChanges, OnInit, OnDestroy {
  @Input() metrics: SubGroupType<StatisticsPushNotificationInterface>[];
  dataSource = new MatTableDataSource<PushNotificationTopContentTableDataInterface>([]);
  columns = this.getColumns();
  pageSize = 3;
  pageSizeOptions = [this.pageSize];
  filterForm: UntypedFormGroup;
  private readonly unsubscribe$ = new Subject<void>();

  constructor() {
    this.configTable();
    this.initFilterForm();
    this.filter();
  }

  ngOnInit(): void {
    this.configTable();
    this.initFilterForm();
  }
  ngOnChanges(): void {
    this.getTopContentTableData();
    this.filter();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  filter(): void {
    if (!this.dataSource) return;
    this.dataSource.filter = JSON.stringify(this.filterForm.getRawValue());
  }

  private initFilterForm(): void {
    this.filterForm = createFormGroup({data : {
      eventUrl: '',
    }});
    this.filterForm.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(this.filter.bind(this));
  }
  getTopContentTableData(): void {
    if (!this.metrics) return;
    const topContentTableData: PushNotificationTopContentTableDataInterface[] = [];
    this.metrics.forEach(({ eventUrl, topic, eventDateTime, eventAction, totalActivations }) => {
      const eventDate = eventDateTime.toLocaleDateString();
      const eventTime = eventDateTime.toLocaleTimeString();
      const found = topContentTableData.find(
        topContentRow =>
          eventUrl === topContentRow.eventUrl &&
          topic === topContentRow.topic &&
          eventDate === topContentRow.eventDate &&
          eventTime === topContentRow.eventTime
      );
      if (found) {
        found[eventAction] = found[eventAction] + totalActivations;
        found.totalActivations = found.totalActivations + totalActivations;
        return;
      }
      topContentTableData.push({
        eventUrl,
        topic,
        eventDate,
        eventTime,
        totalActivations: totalActivations,
        [PUSH_NOTIFICATION_STATISTIC_STATUS.RECEIVED]: 0,
        [PUSH_NOTIFICATION_STATISTIC_STATUS.OPENED]: 0,
        [PUSH_NOTIFICATION_STATISTIC_STATUS.CLOSED]: 0,
        [eventAction]: totalActivations,
      });
    });

    topContentTableData.sort((topContentRowA, topContentRowB) => {
      return topContentRowB.totalActivations - topContentRowA.totalActivations;
    });
    this.dataSource.data = topContentTableData;
  }

  private getColumns(): TableColumn<SubGroupType<StatisticsPushNotificationInterface>>[] {
    const columns = [];
    columns.push({
      Title: PUSH_NOTIFICATION_COLUMN_NAME.eventUrl,
      columnDef: 'eventUrl',
      type: 'text',
      Value: (pushData: any): string => String(pushData['eventUrl']),
      isSortable: true,
      isCopyText: true,
    });

    PUSH_NOTIFICATION_TOP_CONTENT_COLUMNS.forEach(key => {
      if (PUSH_NOTIFICATION_COLUMN_NAME[key]) {
        columns.push({
          Title: PUSH_NOTIFICATION_COLUMN_NAME[key],
          columnDef: key,
          type: 'text',
          Value: (pushData: any): string => String(pushData[key]),
          isSortable: true,
          isCentered: true,
        });
      }
    });
    columns.push({
      Title: PUSH_NOTIFICATION_STATISTIC_STATUS_TITLE[PUSH_NOTIFICATION_STATISTIC_STATUS.RECEIVED],
      columnDef: PUSH_NOTIFICATION_STATISTIC_STATUS.RECEIVED,
      type: 'text',
      Value: (pushData: any): string => String(pushData[PUSH_NOTIFICATION_STATISTIC_STATUS.RECEIVED]),
      isSortable: true,
      isCentered: true,
    });
    columns.push({
      Title: PUSH_NOTIFICATION_STATISTIC_STATUS_TITLE[PUSH_NOTIFICATION_STATISTIC_STATUS.OPENED],
      columnDef: PUSH_NOTIFICATION_STATISTIC_STATUS.OPENED,
      type: 'text',
      Value: (pushData: any): string =>
        this.toStringWithPercentage(
          pushData[PUSH_NOTIFICATION_STATISTIC_STATUS.OPENED],
          pushData[PUSH_NOTIFICATION_STATISTIC_STATUS.RECEIVED]
        ),
      isSortable: true,
      isCentered: true,
    });
    columns.push({
      Title: PUSH_NOTIFICATION_STATISTIC_STATUS_TITLE[PUSH_NOTIFICATION_STATISTIC_STATUS.CLOSED],
      columnDef: PUSH_NOTIFICATION_STATISTIC_STATUS.CLOSED,
      type: 'text',
      Value: (pushData: any): string =>
        this.toStringWithPercentage(
          pushData[PUSH_NOTIFICATION_STATISTIC_STATUS.CLOSED],
          pushData[PUSH_NOTIFICATION_STATISTIC_STATUS.RECEIVED]
        ),
      isSortable: true,
      isCentered: true,
    });
    return columns;
  }

  private configTable(): void {
    this.dataSource.filterPredicate = (pushNotification, filter): boolean =>
      Object.entries(JSON.parse(filter)).every(([key, value]) => {
        if (value === '' || value === undefined || value === null) return true;
        switch (key) {
          case 'eventUrl':
            return pushNotification.eventUrl.includes(String(value));
          default:
            return true;
        }
      });
    this.dataSource.sortingDataAccessor = (item: any, property: string): string | number => {
      switch (property) {
        default:
          return item[property];
      }
    };
  }

  private percentage(value: number, total: number): number {
    if (!total) return 0;
    return Math.round((value / total) * 1000) / 10;
  }

  private toStringWithPercentage(value: number, total: number): string {
    return `${String(value)} (${this.percentage(value, total)}%)`;
  }
}
