import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { from, Observable, of } from 'rxjs';
import { debounceTime, map, switchMap, take } from 'rxjs/operators';
import { CONSTANTS } from 'src/app/shared/utils/constants';
import { environment } from 'src/environments/environment';
import { UserInterface } from '../interfaces/user.interface';
import { UserService } from './user.service';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import { User } from '../models/user.model';
import { Router } from '@angular/router';
import { seconds } from '../../thalia/shared/thalia-constants';

@Injectable()
export class AuthService {
    public user$: Observable<UserInterface>;
    public userDetails: UserInterface = null;

    constructor(private readonly firestoreAuth: AngularFireAuth, private readonly userService: UserService, private readonly router: Router) {
        this.user$ = this.firestoreAuth.authState.pipe(
          // TODO en este flujo se establece el userDetails, pero depende de que alguien se suscriba, darle una vuelta.
          switchMap((authUser: firebase.User): Observable<UserInterface> => {
            if(authUser && !this.isLoggedTimeExceeded(authUser)) {
              return this.userService.get(authUser.uid).pipe(
                map((user: UserInterface): UserInterface => {
                  if (user) {
                    user.emailVerified = authUser.emailVerified;
                    user.lastSignInTime = firebase.firestore.Timestamp.now();
                    this.userDetails = new User(user);
                  }
                  return this.userDetails;
                })
              );
            } else {
              this.userDetails = undefined;
              return of(null);
            }
          })
        );

        this.user$.pipe(debounceTime(seconds(1))).subscribe({
          next: (user)=> {
            if (!user) {
              console.log('User not found. Redirecting to login');
              this.router.navigate(['/login']);
            }
          }
        });
    }

    async signIn(email: string, password: string) {
      const credential = await this.firestoreAuth.signInWithEmailAndPassword(email, password);
      const user = await this.updateUserData(credential.user);
      this.updateSignIngTime(user);

      if(!(credential === null) && !(credential.user ===null) && !credential.user.emailVerified) {
        credential.user.sendEmailVerification();
      }

      return user;
  }
    public signUp(email: string, password: string): Observable<firebase.User> {
        return from(
            this.getSecondaryApp()
                .auth()
                .createUserWithEmailAndPassword(email, password)
        ).pipe(map((credential: firebase.auth.UserCredential): firebase.User => credential.user));
    }

    public logout() {
        return this.firestoreAuth.signOut();
    }

    public logoutFromTemporalApp(): Observable<void> {
        return from(
            this.getSecondaryApp()
                .auth()
                .signOut()
        );
    }

    public sendEmailVerification(email: string): Observable<void> {
        const actionCodeSettings = {
            url: CONSTANTS.EMAIL_ACTIONS_URL + email,
        };
        return from(
            this.getSecondaryApp()
                .auth()
                .currentUser.sendEmailVerification(actionCodeSettings)
        );
    }

    public sendEmailPasswordReset(email: string): Observable<void> {
        const actionCodeSettings = {
            url: CONSTANTS.EMAIL_ACTIONS_URL + email,
        };
        return from(this.firestoreAuth.sendPasswordResetEmail(email, actionCodeSettings));
    }

    public verifyPasswordResetCode(actionCode: string): Observable<string> {
        return from(this.firestoreAuth.verifyPasswordResetCode(actionCode));
    }

    public confirmPasswordReset(actionCode: string, newPassword: string): Observable<void> {
        return from(this.firestoreAuth.confirmPasswordReset(actionCode, newPassword));
    }

    public checkActionCode(actionCode: string): Observable<any> {
        return from(this.firestoreAuth.checkActionCode(actionCode));
    }

    public applyActionCode(actionCode: string): Observable<void> {
        return from(this.firestoreAuth.applyActionCode(actionCode));
    }

    hasRole(permission: string): boolean {
      return !!this.userDetails?.roles?.includes(permission);
    }

    public getMedia(): string[] {
        return this.userDetails.medias;
    }

    public getName(): string {
        return this.userDetails.displayName;
    }

    public getEmail(): string {
        return this.userDetails.email;
    }

    public isEmailVerified(): boolean {
        return this.userDetails.emailVerified;
    }

    public isActive(): boolean {
        if (this.userDetails && this.userDetails.active) {
            return true;
        } else {
            return false;
        }
    }

    private updateUserData(authUser: firebase.User) {
        return this.userService.get(authUser.uid).pipe(
            map((user: UserInterface): UserInterface => {
                    if (user) {
                        user.emailVerified = authUser.emailVerified;
                        user.lastSignInTime = firebase.firestore.Timestamp.now();
                        this.userDetails = user;
                    }
                    return this.userDetails;
                }
            ),
            take(1)
        ).toPromise();
    }

    private updateSignIngTime(user: UserInterface) {
      user.lastSignInTime = firebase.firestore.Timestamp.now();
      this.userService.update(user.uid, user);
    }

    private isLoggedTimeExceeded(user: firebase.User): boolean {
      const MAX_MSEC_LOGGED = 3600000;
      const lastSignInTime = user.metadata.lastSignInTime
          ? new Date(user.metadata.lastSignInTime).getTime()
          : Date.now();
      const diffTime = Date.now() - lastSignInTime;
      return diffTime >= MAX_MSEC_LOGGED;
  }

    // Vale la pena revisar si es mejor usar el SDK de admin de Firebase para estas tareas https://firebase.google.com/docs/auth/admin/manage-users#create_a_user
    private getSecondaryApp(): firebase.app.App {
        /**
         * Workaround https://stackoverflow.com/questions/37517208/firebase-kicks-out-current-user/38013551#38013551
         * When creating a new user, createUserWithEmailAndPassword method automatically logs in the new user, kicking out admin user
         * To prevent this, it will be created a secondary instance of firebase to use only for this action
         */
        if (firebase.apps.length) {
            let exists = -1;
            for (let index = 0; index < firebase.apps.length; index++) {
                const element = firebase.apps[index];
                exists = element.name === 'Secondary' ? index : -1;
            }
            if (exists > -1) {
                return firebase.apps[exists];
            } else {
                return firebase.initializeApp(environment.firebaseConfig, 'Secondary');
            }
        } else {
            return firebase.initializeApp(environment.firebaseConfig, 'Secondary');
        }
    }
}
