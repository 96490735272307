import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Observable, combineLatest } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { AuthService } from '../services/auth.service';

import { CONSTANTS } from 'src/app/shared/utils/constants';
import ConstantMessages from 'src/app/shared/utils/constants-messages/constants-messages';
import { Utils } from 'src/app/shared/utils/utils';
import { MustMatchValidator } from 'src/app/shared/validators/must-match.validator';

@Component({
  selector: 'app-firebase-auth',
  templateUrl: './firebase-auth.component.html'
})
export class FirebaseAuthComponent implements OnInit {
    public resetPasswordForm: UntypedFormGroup;
    public actionCode: string = '';
    public mode: string = '';
    public accountEmail: string = '';
    public actionCodeIsValid: boolean = false;
    public isLoaded: boolean = false;
    public availableModes: { resetPassword: string, recoverEmail: string, verifyEmail: string } = {
        resetPassword: CONSTANTS.RESET_PASSWORD_MODE,
        recoverEmail: CONSTANTS.RECOVER_EMAIL_MODE,
        verifyEmail: CONSTANTS.VERIFY_EMAIL_MODE
    };

    constructor(
        private readonly router: Router,
        private readonly route: ActivatedRoute,
        private readonly formBuilder: UntypedFormBuilder,
        private readonly authService: AuthService
    ) { }

    ngOnInit() {
        this.route.queryParams.pipe(
            map((params: Params): { actionCode: string, mode: string } => { return { actionCode: params['oobCode'], mode: params['mode'] } }),
            switchMap((queryParams: { actionCode: string, mode: string }): Observable<any> => {
                this.actionCode = queryParams.actionCode;
                this.mode = queryParams.mode;
                // Handle the user management action.
                switch (queryParams.mode) {
                    case CONSTANTS.RESET_PASSWORD_MODE:
                        // Display reset password handler and UI.
                        return this.authService.verifyPasswordResetCode(this.actionCode);
                    case CONSTANTS.RECOVER_EMAIL_MODE:
                        // Display email recovery handler and UI.
                        return combineLatest([this.authService.checkActionCode(this.actionCode), this.authService.applyActionCode(this.actionCode)]);
                    case CONSTANTS.VERIFY_EMAIL_MODE:
                        // Display email verification handler and UI.
                        return this.authService.applyActionCode(this.actionCode);
                }
            })
        ).subscribe((resp) => {
            this.actionCodeIsValid = true;
            this.isLoaded = true;
            if (this.mode === CONSTANTS.RESET_PASSWORD_MODE) {
                this.accountEmail = resp;
                this.createResetPasswordForm();
            }
        }, err => {
            this.isLoaded = true;
            console.log(err);
        });
    }

    private createResetPasswordForm(): void {
        this.resetPasswordForm = this.formBuilder.group(
            {
                newPassword: ['', Validators.required],
                confirmNewPassword: ['', Validators.required],
            }, { validator: MustMatchValidator('newPassword', 'confirmNewPassword') }
        );
    }

    public async resetPassword(): Promise<void> {
        try {
            const newPassword = this.resetPasswordForm.get('newPassword').value;
            await this.authService.confirmPasswordReset(this.actionCode, newPassword)
            const { value } = await Utils.notify('Contraseña cambiada', ConstantMessages.SuccessType, '');
            if (value) this.finish();
        }
        catch(error) {
            console.log(error);
            const { value } = await Utils.notify(ConstantMessages.ErrorTitle, ConstantMessages.ErrorType, 'Pruebe a restablecer la contraseña de nuevo.');
            if (value) this.finish();
        };
    }

    public arePasswordAndConfirmPasswordEqual(): boolean {
        return this.resetPasswordForm.get('newPassword').value === this.resetPasswordForm.get('confirmNewPassword').value
    }

    private finish(): void {
        this.router.navigate(['..'], { relativeTo: this.route });
    }
}
