import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { Programmable } from 'src/app/core/interfaces/programmable.interface';
import { NOTIFY_MESSAGE } from './constants-messages/constants-messages';
import { CONSTANTS, TIME_PERIODS } from './constants';
import { TriggerPeriodicSchedule } from 'src/app/core/interfaces/trigger-schedule-period.interface';

export class ProgrammableUtils {
         private static formBuilder: UntypedFormBuilder = new UntypedFormBuilder();

         static getNow(): moment.Moment {
           return moment();
         }

         static getNowPlusXMinutes(x: number): moment.Moment {
           return moment()
             .add(x, 'minute')
             .startOf('minute');
         }

         static minProgramDate(): Date {
           return new Date();
         }

         static addProgramDate(programDates: UntypedFormArray, value?: Date): void {
           programDates.push(this.newProgramDateControl(programDates, value));
         }

         static removeProgramDate(programDates: UntypedFormArray, i: number): void {
           programDates.removeAt(i);
         }

         static hasFutureProgramDate(programmable: Programmable): boolean {
           if (!programmable) return false;
           const hasProgramDates = programmable.programDates && programmable.programDates.length;
           const hasSchedules = programmable.hasPeriodicSchedule && programmable.periodicScheduleConfig;
           if (!hasProgramDates && !hasSchedules) return false;
           const futureProgram = programmable.programDates.find(programDate => Date.now() < programDate.seconds * 1000);
           const endPeriodic =
             programmable.periodicScheduleConfig && programmable.periodicScheduleConfig.endDateMiliseconds > Date.now();
           return !!futureProgram || endPeriodic;
         }

         static getPunctualScheduleMessage({ programDates, active }: Programmable): string {
           if (programDates && !programDates.length) return;
           const punctualScheduled = programDates.find(programDate => Date.now() < programDate.seconds * 1000);
           if (!punctualScheduled) return;
           const date = moment(new Date(punctualScheduled.seconds * 1000)).format('YYYY-MM-DD HH:mm:ss');
           return NOTIFY_MESSAGE.TRIGGER.SUMMARY_TEMPLATE.PROGRAM_DATE({ active: active, date: date });
         }

         static getPeriodicScheduleMessage({ hasPeriodicSchedule, periodicScheduleConfig }: Programmable): string {
           if (!hasPeriodicSchedule && !periodicScheduleConfig) return;
           if (!periodicScheduleConfig.endDateMiliseconds || !periodicScheduleConfig.scheduleFrequency) return;
           const expireDate = moment(new Date(periodicScheduleConfig.endDateMiliseconds).toISOString()).format(
             'YYYY-MM-DD'
           );
           const startDate = moment(new Date(periodicScheduleConfig.startDateMiliseconds).toISOString()).format(
             'YYYY-MM-DD'
           );
           const scheduleValidity = `desde las ${periodicScheduleConfig.startTime} hasta las ${periodicScheduleConfig.endTime}. A partir del ${startDate} y caduca el ${expireDate}`;
           return `Está programado para ejecutarse de forma ${this.getScheduleRecurrency(
             periodicScheduleConfig
           )} ${scheduleValidity}`;
         }

         private static getScheduleRecurrency(periodicScheduleConfig: TriggerPeriodicSchedule): string {
           switch (periodicScheduleConfig.scheduleFrequency) {
             case TIME_PERIODS.MONTHLY:
               return `mensual, los días ${periodicScheduleConfig.daysOfMonth}`;
             case TIME_PERIODS.BIWEEKLY:
               return `quincenal, los días ${periodicScheduleConfig.biweeklyCalendar
                 ?.map(dayOfMonth => moment(dayOfMonth).format('YYYY-MM-DD HH:mm'))
                 .join(',')}`;
             case TIME_PERIODS.DAILY:
               return `diaria,`;
             case TIME_PERIODS.WEEKLY:
               return `semanal, los días
            ${periodicScheduleConfig.daysOfWeekConfig
              .map(
                dayOfWeekConfig =>
                  CONSTANTS.DAYS_OF_WEEK_CRON_CONFIG.find(day => day.unixCronKey === dayOfWeekConfig.dayOfWeek).literal
              )
              .join(',')}`;
           }
         }

         private static newProgramDateControl(programDates: UntypedFormArray, date?: Date): UntypedFormGroup {
           const value = date || this.getValueForNewProgramDateControl(programDates);
           return this.formBuilder.group({
             programDate: [value, Validators.required],
           });
         }

         private static getValueForNewProgramDateControl(programDates: UntypedFormArray): Date {
           return programDates.controls.length
             ? this.increaseDateTime(this.getLastProgramDateValue(programDates))
             : this.increaseDateTime();
         }

         private static increaseDateTime(initial?: Date): Date {
           const dateTime = initial ? new Date(initial) : new Date();
           dateTime.setDate(dateTime.getDate() + 1);
           dateTime.setHours(0);
           dateTime.setMinutes(0);
           dateTime.setSeconds(0);
           dateTime.setMilliseconds(0);
           return dateTime;
         }

         private static getLastProgramDateValue(programDates: UntypedFormArray): Date {
           return programDates.controls.slice(-1)[0].get('programDate').value as Date;
         }

         static getPeriodDefaultValues(programmable: {
           hasPeriodicSchedule?: boolean;
           periodicScheduleConfig?: TriggerPeriodicSchedule;
         }): { hasPeriodicSchedule?: boolean; periodicScheduleConfig?: TriggerPeriodicSchedule } {
           if (
             !!programmable &&
             programmable.hasPeriodicSchedule &&
             programmable.periodicScheduleConfig &&
             programmable.periodicScheduleConfig.endDateMiliseconds < Date.now()
           ) {
             return { ...programmable, hasPeriodicSchedule: false, periodicScheduleConfig: null  };
           }
           return { ...programmable  };
         }
       }
