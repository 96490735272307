<h5>{{ title }}</h5>

<div class="row">
  <section class="col-6 mb-4">
    <header>Editor</header>
    <article class="card">
      <ul class="d-flex m-0 p-0 editorBar">
        <li *ngFor="let feature of features; trackBy: trackByFeatureTag">
          <button [title]="feature.title" (click)="inputTag(feature.tag)" [innerHTML]="feature.innerHtml"></button>
        </li>
      </ul>

      <div class="separator"></div>

      <mat-form-field class="p-0" appearance="outline">
        <textarea matInput name="control" [formControl]="control" rows="10" (blur)="storeCursor($event)"></textarea>
      </mat-form-field>
    </article>
  </section>

  <section class="col-6">
    <header>Previsualización</header>
    <article class="card d-flex" id="preview-card" #divResizable>
      <div class="preview" #divPreview></div>
      <div class="draggable" (mousedown)="startResize($event)">||</div>
    </article>
    <p class="mt-2">Anchura ventana previsualización: {{ sumWidth }}px</p>
  </section>
</div>
