<div class="mt-4" *ngIf="isErrorPanelVisible">
  <mat-expansion-panel *ngIf='errors' class="alwaysExpanded" [expanded]="true" hideToggle>
    <mat-expansion-panel-header>
      <mat-panel-title>
        <mat-icon>report</mat-icon>
        Errores
        <mat-icon>report</mat-icon>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <app-error-list-item [recursiveErrorsNode]='errors'></app-error-list-item>
  </mat-expansion-panel>
</div>
