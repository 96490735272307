import { environment } from '../../../environments/environment';

export class ConstantsStatistics {
  // DRUID ENDPOINTS
  public static readonly API_DRUID_WIDGETS_WITH_DATA = `${environment.druidMetricsApi}widget/widgets-with-data`;
  public static readonly API_DRUID_WIDGET_WITH_DATA = `${environment.druidMetricsApi}widget/widget-with-data`;
  public static readonly API_DRUID_WIDGET_ALLDAYS_METRICS = `${environment.druidMetricsApi}widget/allDays`;
  public static readonly API_DRUID_WIDGET_ALLHOURS_METRICS = `${environment.druidMetricsApi}widget/allHours`;
  public static readonly API_DRUID_WIDGET_CAMPAIGN_METRICS = `${environment.druidMetricsApi}widget/campaign`;
  public static readonly API_DRUID_WIDGET_ARTICLES_METRICS = `${environment.druidMetricsApi}widget/articles`;
  public static readonly API_DRUID_WIDGET_SEGMENTS_METRICS = `${environment.druidMetricsApi}widget/segments`;

  public static readonly API_DRUID_DATASOURCES_WITH_DATA = `${environment.druidMetricsApi}data_source/datasources-with-data`;
  public static readonly API_DRUID_DATASOURCE_WITH_DATA = `${environment.druidMetricsApi}data_source/datasource-with-data`;

  public static readonly API_DRUID_TESTS_AB_WIDGETS_WITH_DATA = `${environment.druidMetricsApi}test-ab-widgets/tests-ab-widgets-with-data`;
  public static readonly API_DRUID_TEST_AB_WIDGETS_WITH_DATA = `${environment.druidMetricsApi}test-ab-widgets/test-ab-widgets-with-data`;
  public static readonly API_DRUID_TESTAB_WIDGETS_METRICS = `${environment.druidMetricsApi}test-ab-widgets/allDays`;
  public static readonly API_DRUID_TESTAB_WIDGETS_SEGMENTS_METRICS = `${environment.druidMetricsApi}test-ab-widgets/segments`;

  public static readonly API_DRUID_TRIGGER_SEGMENTS_METRICS = `${environment.druidMetricsApi}segments/triggers-by-segment`;
  public static readonly API_DRUID_PUSH_NOTIFICATION_METRICS = `${environment.druidMetricsApi}push-notifications/push-notification-by-topic`;

  // NODE METRICS ENDPOINTS
  public static readonly API_NODE_TRIGGERS_WITH_DATA = `${environment.metricsAPI}triggers/triggers-with-data`;
  public static readonly API_NODE_TRIGGER_WITH_DATA = `${environment.metricsAPI}triggers/trigger-with-data`;
  public static readonly API_NODE_TRIGGERS_METRICS_GROUPED_BY_DATE = `${environment.metricsAPI}triggers/group-agg-by-day`;
  public static readonly API_NODE_TRIGGERS_METRICS_BY_NUMBER_OF_IMPACTED_USERS = `${environment.metricsAPI}triggers/impactedUsers`;
  public static readonly API_NODE_TRIGGERS_METRICS_GROUPED_BY_SEGMENT = `${environment.metricsAPI}triggers/triggerer-segments`;

  public static readonly API_NODE_TESTS_AB_WITH_DATA = `${environment.metricsAPI}tests-ab/tests-ab-with-data`;
  public static readonly API_NODE_TEST_AB_WITH_DATA = `${environment.metricsAPI}tests-ab/test-ab-with-data`;
  public static readonly API_NODE_TESTS_AB_METRICS_GROUPED_BY_DATE = `${environment.metricsAPI}tests-ab/group-agg-by-day`;
  public static readonly API_NODE_TESTS_AB_METRICS = `${environment.metricsAPI}tests-ab/data`;
  public static readonly API_NODE_TESTS_AB_IMPACTED_USERS = `${environment.metricsAPI}tests-ab/impactedUsers`;
  public static readonly API_NODE_TESTS_AB_SEGMENTS_TRIGGERS = `${environment.metricsAPI}tests-ab/trigger-segments`;

  public static readonly API_NODE_SEGMENTS_WITH_DATA = `${environment.metricsAPI}segment/segments-with-data`;
  public static readonly API_NODE_SEGMENT_WITH_DATA = `${environment.metricsAPI}segment/segment-with-data`;
  public static readonly API_NODE_SEGMENTS_METRICS_GROUPED_BY_DATE = `${environment.metricsAPI}segment/allDays`;
  public static readonly API_NODE_SEGMENTS_METRICS_BY_NUMBER_OF_IMPACTED_USERS = `${environment.metricsAPI}segment`;

  public static readonly API_NODE_ARTICLES_TRIGGERS = `${environment.metricsAPI}triggers/triggers-articles`;
  public static readonly API_NODE_CATEGORIES_TRIGGERS = `${environment.metricsAPI}triggers/triggers-categories`;

  // CLOUD FUNCTIONS
  public static readonly CLOUD_FUNCTION_WIDGETS_WITH_DATA_APPS = `${environment.cloudFunctionsAPI}getWidgetsWithDataInApps/widgets/widget-apps-with-data`;
  public static readonly CLOUD_FUNCTION_WIDGET_WITH_DATA_APPS = `${environment.cloudFunctionsAPI}getWidgetWithDataInApps`;
  public static readonly CLOUD_FUNCTION_WIDGETS_METRICS_BY_DATE_APPS = `${environment.cloudFunctionsAPI}getWidgetStatsInApps/widgets/:widgetId/widget-apps-stats`;
  public static readonly CLOUD_FUNCTION_WIDGETS_METRICS_BY_ARTICLE_APPS = `${environment.cloudFunctionsAPI}getWidgetArticlesStatsInApps/widgets/:widgetId/widget-apps-articles`;

  public static readonly CLOUD_FUNCTION_TRIGGERS_WITH_DATA_APPS = `${environment.cloudFunctionsAPI}getTriggersWithDataInApps/triggers/trigger-apps-with-data`;
  public static readonly CLOUD_FUNCTION_TRIGGER_WITH_DATA_APPS = `${environment.cloudFunctionsAPI}getTriggerWithDataInApps`;
  public static readonly CLOUD_FUNCTION_TRIGGERS_METRICS_BY_DATE_APPS = `${environment.cloudFunctionsAPI}getTriggerStatsInApps/triggers/:triggerId/triggers-stats-apps`;
  public static readonly CLOUD_FUNCTION_TRIGGERS_IMPACTED_USERS_APPS = `${environment.cloudFunctionsAPI}getTriggerImpactedUsersInApps/triggers/:triggerId/impactedUsers-apps`;
  public static readonly CLOUD_FUNCTION_TESTS_AB_METRICS_GROUPED_BY_SEGMENT = `${environment.cloudFunctionsAPI}getTestAbSegmentsStats/tests-ab/:testAbId/test-ab-segments`;

  public static readonly CLOUD_FUNCTION_SEGMENTS_METRICS_BY_NUMBER_OF_IMPACTED_USERS = `${environment.cloudFunctionsAPI}getSegmentsTotalStats/segments/:segmentId/total-stats`;
  public static readonly CLOUD_FUNCTION_SEGMENTS_METRICS_GROUPED_BY_TRIGGER = `${environment.cloudFunctionsAPI}getSegmentedTriggersStats/segments/:segmentId/segmented-triggers`;

  public static readonly CLOUD_FUNCTION_PUSH_SUBSCRIPTION_METRICS = `${environment.cloudFunctionsAPI}getPushSubscriptionsStats`;
  public static readonly CLOUD_FUNCTION_PUSH_SUBSCRIPTION_METRICS_ARTICLES = `${environment.cloudFunctionsAPI}getPushSubscriptionsArticleStats`;
  public static readonly CLOUD_FUNCTION_PUSH_SENDING_METRICS = `${environment.cloudFunctionsAPI}getPushSendingsStats`;

  // PAGE VIEWS
  public static readonly METRICS_PAGE_VIEWS_GROUPED_BY_HOUR = 'https://metrics-api-pro-wibdbssi3q-ew.a.run.app/api/page-views/group-by-hour';
  public static readonly METRICS_PAGE_VIEWS_GROUPED_BY_URL = 'https://metrics-api-pro-wibdbssi3q-ew.a.run.app/api/page-views/group-by-url';

  // METRICS CARDS
  public static readonly METRICS_SHOW = 'show';
  public static readonly PRIORITY_SHOW = 1; // La prioridad de la métrica 'show' se establece para que aparezca en la primera columna de la tabla de métricas
  public static readonly METRICS_SHOW_CARD = 'Impresiones';
  public static readonly METRICS_CLOSE = 'close';
  public static readonly PRIORITY_CLOSE = 2; // La prioridad de la métrica 'close' se establece para que aparezca en la segunda columna de la tabla de métricas
  public static readonly METRICS_CLOSE_CARD = `Botón 'cierre'`;
  public static readonly PRIORITY_OTHERS = 3; // La prioridad de las métricas que no son 'show' ni 'close' se establece para que aparezcan detras de las dos primeras columnas de la tabla de métricas

  // METRICS CARDS DEFAULT NAMES FOR ACTIONS
  public static readonly METRICS_ACTIVATE = 'activate';
  public static readonly METRICS_ACTIVATE_CARD = 'Botón acción';
  public static readonly METRICS_ACTIVATE1 = 'action1';
  public static readonly METRICS_ACTIVATE1_CARD = 'Botón acción 2';
  public static readonly METRICS_ACTIVATE2 = 'action2';
  public static readonly METRICS_ACTIVATE2_CARD = 'Botón acción 3';

  // HIPATIA SCREEN ROUTES
  public static readonly PUSHN_ROUTE = 'push-notifications';
  public static readonly SEGMENTS_ROUTE = 'segments';
  public static readonly TRIGGERS_ROUTE = 'triggers';
  public static readonly TESTSAB_ROUTE = 'tests-ab';
  public static readonly TESTSAB_WIDGETS_ROUTE = 'tests-ab-widgets';

  // TYPES
  public static widgetType = 'widget';
  public static datasourceType = 'fuente de datos';
  public static triggerType = 'activador';
  public static newsletterType = 'newsletter';
  public static pushNType = 'notificación push';
  public static segmentedDatasourceType = 'fuente de datos segmentada';
  public static testABWidgetsType = 'test A/B de widgets';
  public static testABType = 'test A/B de activadores';
  public static segmentType = 'segmento';

  // EXCEL
  public static decimalPipeLocale = 'es';
}
