import { Component, Input, OnChanges, OnDestroy, ViewChild } from '@angular/core';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { Subject } from 'rxjs';
import { WidgetMetricsSegments, WidgetSegment } from 'src/app/core/interfaces/statistics-widgets-segment.interface';
import { MetricService } from 'src/app/core/services/metric.service';
import { takeUntil, take } from 'rxjs/operators';
import { TableColumn } from 'src/app/shared/components/generic-table/column.interface';
import { Router } from '@angular/router';
import { SegmentService } from 'src/app/core/services/segment.service';
import { SegmentHistoricInterface } from 'src/app/core/interfaces/segment-historic.interface';
import { ConstantsStatistics } from '../../utils/constants-statistics';
import { DatePipe, DecimalPipe } from '@angular/common';

@Component({
  selector: 'app-statistics-widgets-segments',
  templateUrl: './statistics-widgets-segments.component.html',
  styleUrls: ['./statistics-widgets-segments.component.scss']
})
export class StatisticsWidgetsSegmentsComponent implements OnChanges, OnDestroy {
  @Input() dates: { begin: Date, end: Date };
  @Input() widgetId: string;
  @Input() currentSiteId: string;
  @Input() elementType: string;

  @ViewChild(MatSort) public set matSort(sort: MatSort) {
    this.sort = sort;
    this.setDataSourceAttributes();
  }
  @ViewChild(MatPaginator) public set matPaginator(paginator: MatPaginator) {
    this.paginator = paginator;
    this.setDataSourceAttributes();
  }
  private paginator: MatPaginator;
  private sort: MatSort;

  public segments: {id: string, type: string}[] = [];
  public widgetSegments: any[];
  public segmentsLoading: boolean = true;
  public widgetSegmentsTable: MatTableDataSource<any>;
  public metricsSegments: WidgetSegment[];
  public structureSegmentsColumns: TableColumn<WidgetSegment>[] = [
    {
      Title: 'ID',
      columnDef: 'segmentId',
      type: 'url',
      Value: (segment: WidgetSegment) => segment.segmentId,
      ValueUrl: (segment: WidgetSegment) => {
        const index = this.segments.findIndex(seg => seg.id === segment.segmentId.toString());
        if (index !== -1) {
          const typeUrl = this.segments[index].type === 'historical' ? '/segments/list-historical/' : 'segments/list-real-time/';
          return typeUrl + segment.segmentId.toString();
        }
        return '';
      },
      isSortable: true
    },
    {
      Title: 'Impresiones',
      columnDef: 'shows',
      type: 'text',
      Value: (segment: WidgetSegment) => this.decimalPipe.transform(segment.impressions, '', ConstantsStatistics.decimalPipeLocale),
      isSortable: true,
    },
    {
      Title: 'Clics',
      columnDef: 'clics',
      type: 'text',
      Value: (segment: WidgetSegment) => this.decimalPipe.transform(segment.clics, '', ConstantsStatistics.decimalPipeLocale),
      isSortable: true,
    },
    {
      Title: 'CTR',
      columnDef: 'ctr',
      type: 'text',
      Value: (segment: WidgetSegment) => this.getCTRFormated(segment.clics, segment.impressions),
      isSortable: true,
    },
    {
      Title: '',
      columnDef: 'statistics',
      type: 'statistics',
      Value: (segment: WidgetSegment) => '',
    }
  ];

  private unsubscribe: Subject<void> = new Subject<void>();

  constructor(
    private readonly metricService: MetricService,
    private readonly segmentService: SegmentService,
    private readonly router: Router,
    private readonly decimalPipe: DecimalPipe,
    private readonly datePipe: DatePipe
  ) { }

  ngOnChanges(): void {
    this.segmentsLoading = true;
    this.unsubscribe.next();
    this.loadWidgetSegments();
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  private setDataSourceAttributes(): void {
    if (this.widgetSegmentsTable) {
        this.widgetSegmentsTable.paginator = this.paginator;
        this.widgetSegmentsTable.sort = this.sort;
    }
  }

  public sumMetricsArticles(widgetMetricsArticles: WidgetMetricsSegments): any[] {
    const aux1 = [];
    const aux2 = [];

    widgetMetricsArticles.widgetSegments.forEach(w => {
      const index = aux1.findIndex(segment => segment.segmentId === w.segmentId);
      if (index !== -1) aux2.push(w);
      else aux1.push(w);
    });

    aux1.forEach(segment => {
      const segment2 = aux2.find(s => s.segmentId === segment.segmentId);
      if (segment2) {
        segment.impressions += segment2.impressions;
        segment.clics += segment2.clics;
      }
    });

    return aux1;
  }

  private loadSegmentsName() {
    this.metricsSegments.forEach(segment => {
      this.segmentService.getSegmentById(this.currentSiteId, segment.segmentId.toString()).pipe(take(1))
        .subscribe( (result: SegmentHistoricInterface[]): void => {
          if (result.length) this.segments.push({ id: segment.segmentId.toString(), type: 'historical' });
          else this.segments.push({ id: segment.segmentId.toString(), type: 'realtime' });
        },
      )
    });
  }

  private setTableData(): void {
    this.widgetSegmentsTable = new MatTableDataSource(this.metricsSegments);

    this.widgetSegmentsTable.sortingDataAccessor = (item: any, property: string): string | number => {
      switch (property) {
          case 'segmentId':
            return item.segmentId.toString().toLowerCase();
          case 'shows':
            return item.impressions;
          case 'clics':
            return item.clics;
          case 'ctr':
            return item.ctr;
          default:
            return item[property];
          }
      };
      this.segmentsLoading = false;
  }

  private loadWidgetSegments() {
    let observablesMetrics = this.elementType === "widget" ?
                              this.metricService.getAllWidgetActivationsGroupedBySegmentId(this.widgetId, this.currentSiteId, this.dates.begin, this.dates.end) :
                              this.metricService.getAllTestAbWidgetsActivationsGroupedBySegmentId(this.widgetId, this.currentSiteId, this.dates.begin, this.dates.end);
    observablesMetrics.pipe(
      takeUntil(this.unsubscribe)).subscribe((widgetMetricsSegments: WidgetMetricsSegments): void => {
        this.metricsSegments = this.sumMetricsArticles(widgetMetricsSegments);
        this.loadSegmentsName();
        this.setTableData();
    });
  }

  public getCTRFormated(clics: number, impressions: number): string {
    const ctr = clics && impressions ? (clics / impressions)*100 : 0;
    let ctrString = ctr.toFixed(2).toString();
    if (parseInt((ctr / 10).toString()) <= 0) ctrString = '0' + ctrString;

    return ctrString + ' %';
  }

  public async onButtonStatisticsClicked(segment: WidgetSegment): Promise<void> {
    const startDate = this.datePipe.transform(this.dates.begin, 'yyyy-MM-dd');
    const endDate = this.datePipe.transform(this.dates.end, 'yyyy-MM-dd');
    this.router.navigate(['../../statistics/segments'], { queryParams: { id: segment.segmentId, startDate, endDate } });
  }
}
