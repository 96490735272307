export const AppPermissions = {
  PROMETEO: {
    WIDGET_CREATE: { id: 'WIDGET_CREATE', name: 'Crear Widgets' },
    WIDGET_READ: { id: 'WIDGET_READ', name: 'Leer Widgets' },
    WIDGET_UPDATE: { id: 'WIDGET_UPDATE', name: 'Actualizar Widgets' },
    WIDGET_DELETE: { id: 'WIDGET_DELETE', name: 'Eliminar Widgets' },
    WIDGET_COPY: { id: 'WIDGET_COPY', name: 'Copiar Widgets' },
    WIDGET_SHOW_SCRIPT: { id: 'WIDGET_SHOW_SCRIPT', name: 'Visualizar Scripts de Widgets' },

    WIDGET_TESTAB_CREATE: { id: 'WIDGET_TESTAB_CREATE', name: 'Crear Test A/B de Widgets' },
    WIDGET_TESTAB_READ: { id: 'WIDGET_TESTAB_READ', name: 'Leer Test A/B de Widgets' },
    WIDGET_TESTAB_UPDATE: { id: 'WIDGET_TESTAB_UPDATE', name: 'Actualizar Test A/B de Widgets' },
    WIDGET_TESTAB_DELETE: { id: 'WIDGET_TESTAB_DELETE', name: 'Eliminar Test A/B de Widgets' },
    WIDGET_TESTAB_COPY: { id: 'WIDGET_TESTAB_COPY', name: 'Copiar Test A/B de Widgets' },
    WIDGET_TESTAB_SHOW_SCRIPT: { id: 'WIDGET_TESTAB_SHOW_SCRIPT', name: 'Visualizar Scripts de Test A/B de Widgets' },

    DATASOURCE_CREATE: { id: 'DATASOURCE_CREATE', name: 'Crear Fuentes de datos' },
    DATASOURCE_READ: { id: 'DATASOURCE_READ', name: 'Leer Fuentes de datos' },
    DATASOURCE_UPDATE: { id: 'DATASOURCE_UPDATE', name: 'Actualizar Fuentes de datos' },
    DATASOURCE_DELETE: { id: 'DATASOURCE_DELETE', name: 'Eliminar Fuentes de datos' },
    DATASOURCE_COPY: { id: 'DATASOURCE_COPY', name: 'Copiar  Fuentes de datos' },

    TEMPLATE_CREATE: { id: 'TEMPLATE_CREATE', name: 'Crear Plantillas' },
    TEMPLATE_READ: { id: 'TEMPLATE_READ', name: 'Leer Plantillas' },
    TEMPLATE_UPDATE: { id: 'TEMPLATE_UPDATE', name: 'Actualizar Plantillas' },
    TEMPLATE_DELETE: { id: 'TEMPLATE_DELETE', name: 'Eliminar Plantillas' },
    TEMPLATE_COPY: { id: 'TEMPLATE_COPY', name: 'Copiar Plantillas' },
  },

  ACTIVADORES: {
    TRIGGER_CREATE: { id: 'TRIGGER_CREATE', name: 'Crear Activadores' },
    TRIGGER_READ: { id: 'TRIGGER_READ', name: 'Leer Activadores' },
    TRIGGER_UPDATE: { id: 'TRIGGER_UPDATE', name: 'Actualizar Activadores' },
    TRIGGER_DELETE: { id: 'TRIGGER_DELETE', name: 'Eliminar Activadores' },
    TRIGGER_COPY: { id: 'TRIGGER_COPY', name: 'Copiar Activadores' },
    TRIGGER_SHOW_SCRIPT: { id: 'TRIGGER_SHOW_SCRIPT', name: 'Visualizar Scripts de Activadores' },


    COMPATIBLE_TRIGGERS_CREATE: { id: 'COMPATIBLE_TRIGGERS_CREATE', name: ' Crear Activadores Compatibles' },

    TRIGGER_PRIORIZATION: { id: 'TRIGGER_PRIORIZATION', name: 'Priorizar Triggers' },

    TRIGGER_TEMPLATE_CREATE: { id: 'TRIGGER_TEMPLATE_CREATE', name: 'Crear Plantillas de Activadores' },
    TRIGGER_TEMPLATE_READ: { id: 'TRIGGER_TEMPLATE_READ', name: 'Leer Plantillas de Activadores' },
    TRIGGER_TEMPLATE_UPDATE: { id: 'TRIGGER_TEMPLATE_UPDATE', name: 'Actualizar Plantillas de Activadores' },
    TRIGGER_TEMPLATE_DELETE: { id: 'TRIGGER_TEMPLATE_DELETE', name: 'Eliminar Plantillas de Activadores' },
    TRIGGER_TEMPLATE_COPY: { id: 'TRIGGER_TEMPLATE_COPY', name: 'Copiar Plantillas de Activadores' },
    TRIGGER_TEMPLATE_BASIC_UPDATE: { id: 'TRIGGER_TEMPLATE_BASIC_UPDATE', name: 'Actualizar Plantillas Básicas de Activadores' },
    TRIGGER_TEMPLATE_BASIC_DELETE: { id: 'TRIGGER_TEMPLATE_BASIC_DELETE', name: 'Eliminar Plantillas Básicas de Activadores' },
    TRIGGER_TEMPLATE_BASIC_COPY: { id: 'TRIGGER_TEMPLATE_BASIC_COPY', name: 'Copiar Plantillas Básicas de Activadores' },
    TRIGGER_TEMPLATE_BASIC_CONFIG: { id: 'TRIGGER_TEMPLATE_BASIC_CONFIG', name: 'Actualizar Modo Básico de Plantillas de Activadores' },
    TRIGGER_FUNCTION_BASIC_UPDATE: { id: 'TRIGGER_FUNCTION_BASIC_UPDATE', name: 'Editar funciones básicas' },
    TRIGGER_FUNCTION_BASIC_DELETE: { id: 'TRIGGER_FUNCTION_BASIC_DELETE', name: 'Eliminar funciones básicas' },
    TRIGGER_FUNCTION_BASIC_COPY: { id: 'TRIGGER_FUNCTION_BASIC_COPY', name: 'Copiar funciones básicas' },
    TRIGGER_FUNCTION_CREATE: { id: 'TRIGGER_FUNCTION_CREATE', name: 'Crear Funciones de Activadores' },
    TRIGGER_FUNCTION_READ: { id: 'TRIGGER_FUNCTION_READ', name: 'Leer Funciones de Activadores' },
    TRIGGER_FUNCTION_UPDATE: { id: 'TRIGGER_FUNCTION_UPDATE', name: 'Actualizar Funciones de Activadores' },
    TRIGGER_FUNCTION_DELETE: { id: 'TRIGGER_FUNCTION_DELETE', name: 'Eliminar Funciones de Activadores' },
    TRIGGER_FUNCTION_COPY: { id: 'TRIGGER_FUNCTION_COPY', name: 'Copiar Funciones de Activadores' },
    TRIGGER_FUNCTION_BASIC_CONFIG: { id: 'TRIGGER_FUNCTION_BASIC_CONFIG', name: 'Actualizar Modo Básico de Funciones de Activadores' },

    TRIGGER_DROP_DOWN_LIST_TEMPLATE: { id: 'TRIGGER_DROP_DOWN_LIST_TEMPLATE', name: 'Abrir Desplegable de Plantillas (Activadores)'},
    TRIGGER_DROP_DOWN_LIST_FUNCTION: { id: 'TRIGGER_DROP_DOWN_LIST_FUNCTION', name: 'Abrir Desplegable de Funciones (Activadores)'},

    TEST_AB_CREATE: { id: 'TEST_AB_CREATE', name: 'Crear Test A/B' },
    TEST_AB_READ: { id: 'TEST_AB_READ', name: 'Leer Tests A/B' },
    TEST_AB_UPDATE: { id: 'TEST_AB_UPDATE', name: 'Actualizar Test A/B' },
    TEST_AB_DELETE: { id: 'TEST_AB_DELETE', name: 'Eliminar Test A/B' },
  },
  SEGMENTOS: {
    SEGMENT_CREATE: { id: 'SEGMENT_CREATE', name: 'Crear Segmentos' },
    SEGMENT_READ: { id: 'SEGMENT_READ', name: 'Leer Segmentos' },
    SEGMENT_UPDATE: { id: 'SEGMENT_UPDATE', name: 'Actualizar Segmentos' },
    SEGMENT_DELETE: { id: 'SEGMENT_DELETE', name: 'Eliminar Segmentos' },
    SEGMENT_COPY: { id: 'SEGMENT_COPY', name: 'Copiar Segmentos' },

    REAL_TIME_SEGMENT_CREATE: { id: 'REAL_TIME_SEGMENT_CREATE', name: 'Crear Segmentos de Tiempo Real' },
    REAL_TIME_SEGMENT_READ: { id: 'REAL_TIME_SEGMENT_READ', name: 'Leer Segmentos de Tiempo Real' },
    REAL_TIME_SEGMENT_UPDATE: { id: 'REAL_TIME_SEGMENT_UPDATE', name: 'Actualizar Segmentos de Tiempo Real' },
    REAL_TIME_SEGMENT_DELETE: { id: 'REAL_TIME_SEGMENT_DELETE', name: 'Eliminar Segmentos de Tiempo Real' },
    REAL_TIME_SEGMENT_COPY: { id: 'REAL_TIME_SEGMENT_COPY', name: 'Copiar Segmentos de Tiempo Real' },

    SHARED_SEGMENT_CREATE: { id: 'SHARED_SEGMENT_CREATE', name: 'Crear Segmentos Compartidos' },
    SHARED_SEGMENT_READ: { id: 'SHARED_SEGMENT_READ', name: 'Leer Segmentos Compartidos' },
    SHARED_SEGMENT_UPDATE: { id: 'SHARED_SEGMENT_UPDATE', name: 'Actualizar Segmentos Compartidos' },
    SHARED_SEGMENT_DELETE: { id: 'SHARED_SEGMENT_DELETE', name: 'Eliminar Segmentos Compartidos' },
    SHARED_SEGMENT_COPY: { id: 'SHARED_SEGMENT_COPY', name: 'Copiar Segmentos Compartidos' },

    HISTORICAL_OPERATION_READ: { id: 'HISTORICAL_OPERATION_READ', name: 'Leer Operación de Segmentos Históricos' },
    HISTORICAL_OPERATION_CREATE: { id: 'HISTORICAL_OPERATION_CREATE', name: 'Crear Operación de Segmentos Históricos' },
    HISTORICAL_OPERATION_UPDATE: { id: 'HISTORICAL_OPERATION_UPDATE', name: 'Actualizar Operación de Segmentos Históricos' },
    HISTORICAL_OPERATION_DELETE: { id: 'HISTORICAL_OPERATION_DELETE', name: 'Eliminar Operación de Segmentos Históricos' },
    HISTORICAL_OPERATION_COPY: { id: 'HISTORICAL_OPERATION_COPY', name: 'Copiar Operación de Segmentos Históricos' },

    SHARED_HISTORICAL_OPERATION_READ: { id: 'SHARED_HISTORICAL_OPERATION_READ', name: 'Leer Operación de Segmentos Históricos Compartidos' },
    SHARED_HISTORICAL_OPERATION_CREATE: { id: 'SHARED_HISTORICAL_OPERATION_CREATE', name: 'Crear Operación de Segmentos Históricos Compartidos' },
    SHARED_HISTORICAL_OPERATION_UPDATE: { id: 'SHARED_HISTORICAL_OPERATION_UPDATE', name: 'Actualizar Operación de Segmentos Históricos Compartidos' },
    SHARED_HISTORICAL_OPERATION_DELETE: { id: 'SHARED_HISTORICAL_OPERATION_DELETE', name: 'Eliminar Operación de Segmentos Históricos Compartidos' },
    SHARED_HISTORICAL_OPERATION_COPY: { id: 'SHARED_HISTORICAL_OPERATION_COPY', name: 'Copiar Operación de Segmentos Históricos Compartidos' },

    IMPORTED_SEGMENT_READ: { id: 'IMPORTED_SEGMENT_READ', name: 'Leer Segmentos Importados' },

    AD_MANAGER_CREATE: { id: 'AD_MANAGER_CREATE', name: 'Crear Segmento en AdManager' },
  },

  NOTIFICATIONS_PUSH: {
    PUSH_ACCESS: { id: 'PUSH_ACCESS', name: 'Acceder al Módulo de Notificaciones Push' },
    SERVICE_WORKER: { id: 'SERVICE_WORKER', name: 'Habilitar Botón de Descarga del Service Worker' },

    PUSH_CREATE: { id: 'PUSH_CREATE', name: 'Crear Suscripción de Notificaciones' },
    PUSH_READ: { id: 'PUSH_READ', name: 'Leer Suscripción de Notificaciones' },
    PUSH_UPDATE: { id: 'PUSH_UPDATE', name: ' Actualizar Suscripción de Notificaciones' },
    PUSH_DELETE: { id: 'PUSH_DELETE', name: ' Eliminar Suscripción de Notificaciones' },
    PUSH_COPY: { id: 'PUSH_COPY', name: ' Copiar Suscripción de Notificaciones' },
    PUSH_SHOW_SCRIPT: { id: 'PUSH_SHOW_SCRIPT', name: ' Visualizar Scripts de Suscripción de Notificaciones' },

    PUSH_DROP_DOWN_LIST_TEMPLATE: { id: 'PUSH_DROP_DOWN_LIST_TEMPLATE', name: 'Abrir Desplegable de Plantillas (Notificaciones push)'},
    PUSH_DROP_DOWN_LIST_FUNCTION: { id: 'PUSH_DROP_DOWN_LIST_FUNCTION', name: 'Abrir Desplegable de Funciones (Notificaciones push)'},

    SEND_PUSH_READ: { id: 'SEND_PUSH_READ', name: 'Leer Envío de Notificaciones' },
    SEND_PUSH_CREATE: { id: 'SEND_PUSH_CREATE', name: 'Crear Envío de Notificaciones' },
    SEND_PUSH_UPDATE: { id: 'SEND_PUSH_UPDATE', name: 'Actualizar Envío de Notificaciones' },
    SEND_PUSH_DELETE: { id: 'SEND_PUSH_DELETE', name: 'Eliminar Envío de Notificaciones' },

    TEMPLATE_PUSH_READ: { id: 'TEMPLATE_PUSH_READ', name: 'Leer Plantillas de subscripción de Notificaciones' },
    TEMPLATE_PUSH_CREATE: { id: 'TEMPLATE_PUSH_CREATE', name: 'Crear Plantillas de subscripción de Notificaciones' },
    TEMPLATE_PUSH_UPDATE: { id: 'TEMPLATE_PUSH_UPDATE', name: 'Actualizar Plantillas de subscripción de Notificaciones' },
    TEMPLATE_PUSH_DELETE: { id: 'TEMPLATE_PUSH_DELETE', name: 'Eliminar Plantillas de subscripción de Notificaciones' },
    TEMPLATE_PUSH_COPY: { id: 'TEMPLATE_PUSH_COPY', name: 'Copiar Plantillas de subscripción de Notificaciones' },
    TEMPLATE_PUSH_SHOW_SCRIPT: { id: 'TEMPLATE_PUSH_SHOW_SCRIPT', name: ' Visualizar Scripts de plantillas de Suscripción de Notificaciones' },
    TEMPLATE_PUSH_BASIC_CONFIG: { id: 'TEMPLATE_PUSH_BASIC_CONFIG', name: 'Actualizar Modo Básico de Plantillas de Suscripción de Notificaciones' },
    WEB_TOPIC_CREATE: { id: 'WEB_TOPIC_CREATE', name: 'Crear Tópico' },
    WEB_TOPIC_READ: { id: 'WEB_TOPIC_READ', name: 'Leer Tópico' },
    WEB_TOPIC_UPDATE: { id: 'WEB_TOPIC_UPDATE', name: 'Editar Tópico' },
    WEB_TOPIC_DELETE: { id: 'WEB_TOPIC_DELETE', name: 'Eliminar Tópico' },

    TEST_AB_CREATE: { id: 'TEST_AB_PUSH_CREATE', name: 'Crear Test A/B de Notificaciones Push' },
    TEST_AB_READ: { id: 'TEST_AB_PUSH_READ', name: 'Leer Test A/B de Notificaciones Push' },
    TEST_AB_UPDATE:  { id: 'TEST_AB_PUSH_UPDATE', name: 'Editar Test A/B de Notificaciones Push' },
    TEST_AB_DELETE: { id: 'TEST_AB_PUSH_NEWSLETTERS_DELETE', name: 'Eliminar Test A/B de Notificaciones Push' }
  },

  NOTIFICATIONS_PUSH_APPS: {
    PUSH_APPS_ACCESS: { id: 'PUSH_APPS_ACCESS', name: 'Acceder al Módulo de Notificaciones Push de Apps'},
    TOPIC_READ: { id: 'TOPIC_READ', name: 'Leer Tópico de Notificaciones' },
    TOPIC_CREATE: { id: 'TOPIC_CREATE', name: ' Crear Tópico de Notificaciones' },
    TOPIC_UPDATE: { id: 'TOPIC_UPDATE', name: ' Actualizar Tópico de Notificaciones' },
    TOPIC_DELETE: { id: 'TOPIC_DELETE', name: ' Eliminar Tópico de Notificaciones' },
    TOPIC_COPY: { id: 'TOPIC_COPY', name: ' Copiar Tópico de Notificaciones' },

    SEND_PUSH_APP_READ: { id: 'SEND_PUSH_APP_READ', name: 'Leer Envío de Notificaciones' },
    SEND_PUSH_APP_CREATE: { id: 'SEND_PUSH_APP_CREATE', name: 'Crear Envío de Notificaciones' },
    SEND_PUSH_APP_UPDATE: { id: 'SEND_PUSH_APP_UPDATE', name: 'Actualizar Envío de Notificaciones' },
    SEND_PUSH_APP_DELETE: { id: 'SEND_PUSH_APP_DELETE', name: 'Eliminar Envío de Notificaciones' },

  },

  NEWSLETTERS: {
    NEWSLETTER_ACCESS: { id: 'NEWSLETTER_ACCESS', name: 'Acceder al Módulo de Newsletters' },

    NEWSLETTER_CREATE: { id: 'NEWSLETTER_CREATE', name: 'Crear Suscripción a Newsletters' },
    NEWSLETTER_READ: { id: 'NEWSLETTER_READ', name: 'Leer Suscripción a Newsletters' },
    NEWSLETTER_UPDATE: { id: 'NEWSLETTER_UPDATE', name: 'Actualizar Suscripción a Newsletters' },
    NEWSLETTER_DELETE: { id: 'NEWSLETTER_DELETE', name: 'Eliminar Suscripción a Newsletters' },
    NEWSLETTER_COPY: { id: 'NEWSLETTER_COPY', name: 'Copiar Suscripción a Newsletters' },
    NEWSLETTER_SHOW_SCRIPT: { id: 'NEWSLETTER_SHOW_SCRIPT', name: 'Visualizar Scripts de Suscripción a Newsletters' },

    NEWSLETTER_TEMPLATE_CREATE: { id: 'NEWSLETTER_TEMPLATE_CREATE', name: 'Crear plantilla de Suscripción a Newsletters' },
    NEWSLETTER_TEMPLATE_READ: { id: 'NEWSLETTER_TEMPLATE_READ', name: 'Leer plantilla de Suscripción a Newsletters' },
    NEWSLETTER_TEMPLATE_UPDATE: { id: 'NEWSLETTER_TEMPLATE_UPDATE', name: 'Actualizar plantilla de Suscripción a Newsletters' },
    NEWSLETTER_TEMPLATE_DELETE: { id: 'NEWSLETTER_TEMPLATE_DELETE', name: 'Eliminar plantilla de Suscripción a Newsletters' },
    NEWSLETTER_TEMPLATE_COPY: { id: 'NEWSLETTER_TEMPLATE_COPY', name: 'Copiar plantilla de Suscripción a Newsletters' },
    NEWSLETTER_TEMPLATE_BASIC_CONFIG: { id: 'NEWSLETTER_TEMPLATE_BASIC_CONFIG', name: 'Actualizar Modo Básico de Plantillas de Newsletters' },

    NEWSLETTER_DROP_DOWN_LIST_TEMPLATE: { id: 'NEWSLETTER_DROP_DOWN_LIST_TEMPLATE', name: 'Abrir Desplegable de Plantillas (newsletters'},
    NEWSLETTER_DROP_DOWN_LIST_FUNCTION: { id: 'NEWSLETTER_DROP_DOWN_LIST_FUNCTION', name: 'Abrir Desplegable de Funciones (newsletters'},

    NEWSLETTER_AUDIENCE_READ: { id: 'NEWSLETTER_AUDIENCE_READ', name: 'Leer Audiencia de Newsletters' },
    NEWSLETTER_AUDIENCE_CREATE: { id: 'NEWSLETTER_AUDIENCE_CREATE', name: 'Crear Audiencia de Newsletters' },
    NEWSLETTER_AUDIENCE_UPDATE: { id: 'NEWSLETTER_AUDIENCE_UPDATE', name: 'Actualizar Audiencia de Newsletters' },
    NEWSLETTER_AUDIENCE_DELETE: { id: 'NEWSLETTER_AUDIENCE_DELETE', name: 'Eliminar Audiencia de Newsletters' },

    NEWSLETTER_TOPIC_CREATE: { id: 'NEWSLETTER_TOPIC_CREATE', name: 'Crear un tópico' },
    NEWSLETTER_TOPIC_READ: { id: 'NEWSLETTER_TOPIC_READ', name: 'Leer un tópico' },
    NEWSLETTER_TOPIC_UPDATE: { id: 'NEWSLETTER_TOPIC_UPDATE', name: 'Actualizar un tópico' },
    NEWSLETTER_TOPIC_DELETE: { id: 'NEWSLETTER_TOPIC_DELETE', name: 'Eliminar un topico' },

    TEST_AB_CREATE:{ id: 'TEST_AB_NEWSLETTERS_CREATE', name: 'Crear Test A/B de Newsletters' },
    TEST_AB_READ:{ id: 'TEST_AB_NEWSLETTERS_READ', name: 'Leer Test A/B de Newsletters' },
    TEST_AB_UPDATE:{ id: 'TEST_AB_NEWSLETTERS_UPDATE', name: 'Editar Test A/B de Newsletters' },
    TEST_AB_DELETE:{ id: 'TEST_AB_NEWSLETTERS_DELETE', name: 'Eliminar Test A/B de Newsletters' },
  },
  PAYWALL: {
    PAYWALL_ACCESS: { id: 'PAYWALL_ACCESS', name: 'Acceder al Módulo de Paywall' },

    PRODUCT_READ: { id: 'PRODUCT_READ', name: 'Leer Productos' },
    PRODUCT_CREATE: { id: 'PRODUCT_CREATE', name: 'Crear Productos' },
    PRODUCT_COPY: { id: 'PRODUCT_COPY', name: 'Copiar Productos' },
    PRODUCT_UPDATE: { id: 'PRODUCT_UPDATE', name: 'Actualizar Productos' },
    PRODUCT_ARCHIVE: { id: 'PRODUCT_ARCHIVE', name: 'Archivar Productos' },
    PRODUCT_DELETE: { id: 'PRODUCT_DELETE', name: 'Eliminar Productos' },

    FORCE_RETROACTIVITY: { id: 'FORCE_RETROACTIVITY', name: 'Forzar proceso retroactividad' },

    CAMPAIGN_READ: { id: 'CAMPAIGN_READ', name: 'Leer Campañas' },
    CAMPAIGN_CREATE: { id: 'CAMPAIGN_CREATE', name: 'Crear Campañas' },
    CAMPAIGN_UPDATE: { id: 'CAMPAIGN_UPDATE', name: 'Actualizar Campañas' },
    CAMPAIGN_ARCHIVE: { id: 'CAMPAIGN_ARCHIVE', name: 'Archivar Campañas' },
    CAMPAIGN_DELETE: { id: 'CAMPAIGN_DELETE', name: 'Eliminar Campañas' },

    TAX_READ: { id: 'TAX_READ', name: 'Leer Impuestos' },
    TAX_CREATE: { id: 'TAX_CREATE', name: 'Crear Impuestos' },
    TAX_UPDATE: { id: 'TAX_UPDATE', name: 'Actualizar Impuestos' },
    TAX_ARCHIVE: { id: 'TAX_ARCHIVE', name: 'Archivar Impuestos' },

    CUSTOMER_READ: { id: 'CUSTOMER_READ', name: 'Leer clientes' },

    OPERATIONS_READ: { id: 'OPERATIONS_READ', name: 'Ver Operaciones' },

    ORDER_READ: { id: 'ORDER_READ', name: 'Leer Ordenes' },
    ORDER_DETAIL: { id: 'ORDER_DETAIL', name: 'Ver Detalle'},
    ORDER_CANCEL: { id: 'ORDER_CANCEL', name: 'Cancelar'},
    ORDER_REPAYMENT: { id: 'ORDER_REPAYMENT', name: 'Devolución'},
    ORDER_BILL: { id: 'ORDER_BILL', name: 'Ver Albarán'},

    SUBSCRIPTIONS_READ: { id: 'SUBSCRIPTIONS_READ', name: 'Leer suscripciones' },
    SUBSCRIPTIONS_READ_DETAIL: { id: 'SUBSCRIPTIONS_READ_DETAIL', name: 'Ver detalles de suscripciones' },
    SUBSCRIPTIONS_WRITE: { id: 'SUBSCRIPTIONS_WRITE', name: 'Editar suscripciones' },

    PAYWALL_CONFIGURATION: { id: 'PAYWALL_CONFIGURATION', name: 'Configurar el módulo de Paywall' },
    PAYWALL_CONFIGURATION_RESTORE: { id: 'PAYWALL_CONFIGURATION_RESTORE', name: 'Restaurar una versión de configuración de Paywall' },
    PAYWALL_CONFIGURATION_DELETE: { id: 'PAYWALL_CONFIGURATION_DELETE', name: 'Eliminar una versión de configuración de Paywall' },

    VOUCHER_READ: { id: 'VOUCHER_READ', name: 'Leer cupones' },
    VOUCHER_EDIT: { id: 'VOUCHER_EDIT', name: 'Actualizar cupones' },
    VOUCHER_CREATE: { id: 'VOUCHER_CREATE', name: 'Crear cupones' },
    VOUCHER_DETAIL: { id: 'VOUCHER_DETAIL', name: 'Ver cupones' },
    VOUCHER_DELETE: { id: 'VOUCHER_DELETE', name: 'Eliminar cupones' },

    CONTRACTS_READ: { id: 'CONTRACTS_READ', name: 'Ver sección de contratos'},
    CONTRACTS_MANUAL_CONTRACT_WRITE: { id: 'CONTRACTS_MANUAL_CONTRACT_WRITE', name: 'Editar contrato manual'},
    CONTRACTS_MANUAL_CONTRACT_DELETE: { id: 'CONTRACTS_MANUAL_CONTRACT_DELETE', name: 'Eliminar contrato manual'},

    SHOWCASE_READ: { id: 'SHOWCASE_READ', name: 'Ver Escaparate' },

    DEBUG: { id: 'DEBUG', name: 'Ver pagina de debug' },

  },
  STATISTICS: {
    STATISTICS_ACCESS: { id: 'STATISTICS_ACCESS', name: 'Acceder al Módulo de Estadísticas' },

    STATISTICS_READ: { id: 'STATISTICS_READ', name: 'Leer Estadísticas' },

    STATISTICS_WIDGETS_READ: { id: 'STATISTICS_WIDGETS_READ', name: 'Leer Estadísticas de Widgets' },

    STATISTICS_DATASOURCES_READ: { id: 'STATISTICS_DATASOURCES_READ', name: 'Leer Estadísticas de Fuentes de datos' },

    STATISTICS_TRIGGERS_READ: { id: 'STATISTICS_TRIGGERS_READ', name: 'Leer Estadísticas de Activadores' },

    STATISTICS_SEGMENTS_READ: { id: 'STATISTICS_SEGMENTS_READ', name: 'Leer Estadísticas de Segmentos' },

    STATISTICS_NEWSLETTERS_READ: { id: 'STATISTICS_NEWSLETTERS_READ', name: 'Leer Estadísticas de Newsletters' },

    STATISTICS_TESTAB_READ: { id: 'STATISTICS_TESTAB_READ', name: 'Leer Estadísticas de Test A/B Activadores' },

    STATISTICS_TESTAB_WIDGETS_READ: { id: 'STATISTICS_TESTAB_WIDGETS_READ', name: 'Leer Estadísticas de Test A/B Widgets' },

    STATISTICS_PUSH_NOTIFICATIONS_READ: { id: 'STATISTICS_PUSH_NOTIFICATIONS_READ', name: 'Leer Estadísticas de Notificaciones Push' },
  },
  WIKI: {
    WIKI_SHOW: { id: 'WIKI_SHOW', name: 'Mostrar el acceso a la Wiki' }
  },
  ADMIN: {
    ADMIN_ACCESS: { id: 'ADMIN_ACCESS', name: 'Acceder al Módulo de Admin' },

    TOKENS_READ: { id: 'TOKENS_READ', name: 'Leer tokens' },
    TOKENS_CREATE: { id: 'TOKENS_CREATE', name: 'Crear tokens' },
    TOKENS_UPDATE: { id: 'TOKENS_UPDATE', name: 'Actualizar tokens' },
    TOKENS_DELETE: { id: 'TOKENS_DELETE', name: 'Eliminar tokens' },

    USERS_CREATE: { id: 'USERS_CREATE', name: 'Crear Usuarios' },
    USERS_READ: { id: 'USERS_READ', name: 'Leer Usuarios' },
    USERS_UPDATE: { id: 'USERS_UPDATE', name: 'Actualizar Usuarios' },
    USERS_DELETE: { id: 'USERS_DELETE', name: 'Eliminar Usuarios' },
    USERS_COPY: { id: 'USERS_COPY', name: 'Copiar Usuarios' },

    ACCOUNT_CREATE: { id: 'ACCOUNT_CREATE', name: 'Crear Cuentas' },
    ACCOUNT_READ: { id: 'ACCOUNT_READ', name: 'Leer Cuentas' },
    ACCOUNT_UPDATE: { id: 'ACCOUNT_UPDATE', name: 'Actualizar Cuentas' },
    ACCOUNT_DELETE: { id: 'ACCOUNT_DELETE', name: 'Eliminar Cuentas' },

    SITE_CREATE: { id: 'SITE_CREATE', name: 'Crear Sitios' },
    SITE_READ: { id: 'SITE_READ', name: 'Leer Sitios' },
    SITE_UPDATE: { id: 'SITE_UPDATE', name: 'Actualizar Sitios' },
    SITE_DELETE: { id: 'SITE_DELETE', name: 'Eliminar Sitios' },


    PERMISSIONS_READ: { id: 'PERMISSIONS_READ', name: 'Leer Permisos de Cuentas' },
    PERMISSIONS_UPDATE: { id: 'PERMISSIONS_UPDATE', name: 'Actualizar Permisos de Cuentas' },

    ROLES_READ: { id: 'ROLES_READ', name: 'Leer Permisos de Roles' },

    AUTHENTICATION_READ: { id: 'AUTHENTICATION_READ', name: 'Leer Autenticación' },

    DIMENSIONS_CREATE: { id: 'DIMENSIONS_CREATE', name: 'Crear dimensiones' },
    DIMENSIONS_READ: { id: 'DIMENSIONS_READ', name: 'Leer dimensiones' },
    DIMENSIONS_UPDATE: { id: 'DIMENSIONS_UPDATE', name: 'Actualizar dimensiones' },
    DIMENSIONS_DELETE: { id: 'DIMENSIONS_DELETE', name: 'Eliminar dimensiones' },

    UTM_CAMPAIGNS_CREATE: { id: 'UTM_CAMPAIGNS_CREATE', name: 'Crear campaña utm' },
    UTM_CAMPAIGNS_READ: { id: 'UTM_CAMPAIGNS_READ', name: 'Leer campaña utm' },
    UTM_CAMPAIGNS_UPDATE: { id: 'UTM_CAMPAIGNS_UPDATE', name: 'Actualizar campaña utm' },
    UTM_CAMPAIGNS_COPY: { id: 'UTM_CAMPAIGNS_COPY', name: 'Copiar campaña utm' },
    UTM_CAMPAIGNS_DELETE: { id: 'UTM_CAMPAIGNS_DELETE', name: 'Eliminar campaña utm' },

  },
  OTROS: {
    NOTIFICATIONS_ACCESS: { id: 'NOTIFICATIONS_ACCESS', name: 'Acceder a la Sección de Notificaciones' },
  },
  RETAIL_MEDIA: {
    RETAIL_MEDIA_ACCESS: { id: 'RETAIL_MEDIA_ACCESS', name: 'Acceder al Módulo de Retail Media' },
    CAMPAIGN_READ_RETAIL: { id: 'CAMPAIGN_READ_RETAIL', name: 'Leer Campañas' },
    CAMPAIGN_CREATE_RETAIL: { id: 'CAMPAIGN_CREATE_RETAIL', name: 'Crear Campañas' },
    CAMPAIGN_UPDATE_RETAIL: { id: 'CAMPAIGN_UPDATE_RETAIL', name: 'Actualizar Campañas' },
    CAMPAIGN_DELETE_RETAIL: { id: 'CAMPAIGN_DELETE_RETAIL', name: 'Eliminar Campañas' },
    CAMPAIGN_COPY_RETAIL: { id: 'CAMPAIGN_COPY_RETAIL', name: 'Copiar Campañas' },
    CAMPAIGN_UNSUBSCRIBE_RETAIL: { id: 'CAMPAIGN_UNSUBSCRIBE_RETAIL', name: 'Dar de baja Campañas' },
  },
  DATA_CLEAN_ROOM: { //DCR (Data clean room)
    DATA_CLEAN_ROOM_ACCESS: { id: 'DCR_ACCESS', name: 'Acceder al Módulo de Data Clean Room' },
    DATA_CLEAN_ROOM_OPERATIONS_ACCESS: { id: 'DATA_CLEAN_ROOM_OPERATIONS_CREATE', name: 'Acceder Submódulo Segmentos de Operaciones' },
    DATA_CLEAN_ROOM_OPERATIONS_CREATE: { id: 'DATA_CLEAN_ROOM_OPERATIONS_CREATE', name: 'Crear Segmentos de Operaciones' },
    DATA_CLEAN_ROOM_OPERATIONS_UPDATE: { id: 'DATA_CLEAN_ROOM_OPERATIONS_UPDATE', name: 'Modificar Segmentos de Operaciones' },
    DATA_CLEAN_ROOM_OPERATIONS_DELETE: { id: 'DATA_CLEAN_ROOM_OPERATIONS_DELETE', name: 'Eliminar Segmentos de Operaciones' },
    DATA_CLEAN_ROOM_OPERATIONS_COPY: { id: 'DATA_CLEAN_ROOM_OPERATIONS_COPY', name: 'Copiar Segmentos de Operaciones' },
    SEGMENTS: {
      READ: { id: 'DCR_SEGMENTS_READ', name: 'Leer segmentos' },
      CREATE: { id: 'DCR_SEGMENTS_CREATE', name: 'Crear segmentos' },
      UPDATE: { id: 'DCR_ROOM_SEGMENTS_UPDATE', name: 'Actualizar segmentos' },
      DELETE: { id: 'DCR_ROOM_SEGMENTS_DELETE', name: 'Eliminar segmentos' },
    },
    IMPORTED_SEGMENTS: {
      READ: { id: 'DCR_IMPORTED_SEGMENTS_READ', name: 'Leer segmentos importados' },
      CREATE: { id: 'DCR_IMPORTED_SEGMENTS_CREATE', name: 'Crear segmentos importados' },
      UPDATE: { id: 'DCR_IMPORTED_SEGMENTS_UPDATE', name: 'Actualizar segmentos importados' },
      DELETE: { id: 'DCR_IMPORTED_SEGMENTS_DELETE', name: 'Eliminar segmentos importados' },
    },
  },
  ATTRIBUTIONS: {
    ACCESS: { id: 'ATTRIBUTIONS_ACCESS', name: 'Acceder al módulo de atribuciones' },
    EVENTS: {
      READ: { id: 'ATT_EVENT_READ', name: 'Leer eventos' },
      CREATE: { id: 'ATT_EVENT_CREATE', name: 'Create eventos' },
      UPDATE: { id: 'ATT_EVENT_UPDATE', name: 'Actualizar eventos' },
      DELETE: { id: 'ATT_EVENT_DELETE', name: 'Eliminar eventos' },
      COPY: { id: 'ATT_EVENT_COPY', name: 'Copiar Eventos' },
      UNSUBSCRIBE: { id: 'ATT_EVENT_UNSUBSCRIBE', name: 'Dar de baja Eventos' },
    },
    JOURNEYS: {
      READ: { id: 'ATT_JOURNEY_READ', name: 'Leer journeys' },
      CREATE: { id: 'ATT_JOURNEY_CREATE', name: 'Crear journeys' },
      UPDATE: { id: 'ATT_JOURNEY_UPDATE', name: 'Actualizar journeys' },
      DELETE: { id: 'ATT_JOURNEY_DELETE', name: 'Eliminar journeys' },
      COPY: { id: 'ATT_JOURNEY_COPY', name: 'Copiar Journeys' },
      UNSUBSCRIBE: { id: 'ATT_JOURNEY_UNSUBSCRIBE', name: 'Dar de baja Journeys' },
    },
  },
  THALIA: {
    THALIA_ACCESS: { id: 'THALIA_ACCESS', name: 'Acceder al Módulo ThalIA' },

    CONTENT: {
      READ: { id: 'CONTENT_READ', name: 'Leer contenido creado' },
      CREATE: { id: 'CONTENT_CREATE', name: 'Crear contenido' },
      DELETE: { id: 'CONTENT_DELETE', name: 'Eliminar contenido' },
    },

    PROMPT: {
      READ: { id: 'PROMPT_READ', name: 'Leer Prompts' },
      CREATE: { id: 'PROMPT_CREATE', name: 'Crear Prompts' },
      UPDATE: { id: 'PROMPT_UPDATE', name: 'Actualizar Prompts' },
      DELETE: { id: 'PROMPT_DELETE', name: 'Eliminar Prompts' },
    },

    MODEL: {
      MODEL_MODULE_ACCESS: { id: 'MODEL_MODULE_ACCESS', name: 'Acceder al módulo de Modelos' },
      MODEL_CONTENT_GENERATION_READ: { id: 'MODEL_CONTENT_GENERATION_READ', name: 'Leer generación de contenido' },
      MODEL_CONTENT_GENERATION_CREATE: { id: 'MODEL_CONTENT_GENERATION_CREATE', name: 'Crear generación de contenido' },
      MODEL_CONTENT_GENERATION_UPDATE: { id: 'MODEL_CONTENT_GENERATION_UPDATE', name: 'Actualizar generación de contenido' },
      MODEL_CONTENT_GENERATION_DELETE: { id: 'MODEL_CONTENT_GENERATION_DELETE', name: 'Eliminar generación de contenido' },
      MODEL_TRANSCRIPTS_READ: { id: 'MODEL_TRANSCRIPTS_READ', name: 'Leer transcripciones' },
      MODEL_TRANSCRIPTS_CREATE: { id: 'MODEL_TRANSCRIPTS_CREATE', name: 'Crear transcripciones' },
      MODEL_TRANSCRIPTS_UPDATE: { id: 'MODEL_TRANSCRIPTS_UPDATE', name: 'Actualizar transcripciones' },
      MODEL_TRANSCRIPTS_DELETE: { id: 'MODEL_TRANSCRIPTS_DELETE', name: 'Eliminar transcripciones' },
    },

    TRANSCRIPTS: {
      TRANSCRIPTS_MODULE_ACCESS: { id: 'TRANSCRIPTS_MODULE_ACCESS', name: 'Acceder al módulo de Transcripciones' },
      READ: { id: 'TRANSCRIPTS_READ', name: 'Leer transcripciones' },
      CREATE: { id: 'TRANSCRIPTS_CREATE', name: 'Crear transcripciones' },
      EDIT: { id: 'TRANSCRIPTS_EDIT', name: 'Editar transcripciones' },
      DELETE: { id: 'TRANSCRIPTS_DELETE', name: 'Eliminar transcripciones' },
    },
  }
};
