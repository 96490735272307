import { Component, OnInit} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/core/services/auth.service';
import { Utils } from 'src/app/shared/utils/utils';
import { UserInterface } from '../interfaces/user.interface';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
})
export class LoginComponent implements OnInit{

    public formGroupLogin: UntypedFormGroup;
    public errorMessage: string;

    constructor(
        private readonly router: Router,
        private readonly formBuilder: UntypedFormBuilder,
        private readonly authService: AuthService,
    ) {}



    public ngOnInit(): void {
        this.createLoginForm();
    }
    private createLoginForm(): void {
        this.formGroupLogin = this.formBuilder.group({
            email: ['', [Validators.email, Validators.required]],
            password: ['', Validators.required],
        });
    }

    public signIn(): void {
        const email = this.formGroupLogin.get('email').value;
        const password = this.formGroupLogin.get('password').value;
        this.authService
            .signIn(email, password)
            .then(
                (user: UserInterface) => {
                  if (user === null) {
                    Utils.notify(
                    'Usuario no encontrado',
                    'error',
                    'Por favor hable con el administrador');

                  } else if (!user.emailVerified) {
                    Utils.notify(
                    'Usuario no verificado',
                    'error',
                    'Por favor verifique su cuenta');
                  } else if (!user.active) {
                    Utils.notify(
                    'Usuario no activo',
                    'error',
                    'Por favor hable con el administrador');
                  } else {
                    this.router.navigate(['/medias']);
                  }
                }

              ).catch((err: { code: string; message: string }): void => {
                console.log(err);
                Utils.notify(
                  'Error al iniciar sesión',
                  'error',
                  'Credenciales incorrectas',
                );
              });
    }
}
