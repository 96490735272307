import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, DocumentChangeAction } from '@angular/fire/compat/firestore';
import { combineLatest, Observable } from 'rxjs';
import { RoleInterface } from '../interfaces/role.interface';
import { map, shareReplay, tap } from 'rxjs/operators';
import { CONSTANTS } from 'src/app/shared/utils/constants';
import { AuthService } from './auth.service';
import { RolePermissions } from 'src/app/shared/types/types';
import { collection, collectionData, getFirestore } from '@angular/fire/firestore';

const ROLES_COLLECTION = 'roles';

const TemporalNameMapper = {
  account: 'Gestor',
  admin: 'Admin',
  property: 'Propietario',
  reader: 'Lector',
  keyaccount: 'Superadmin'
};

@Injectable()
export class RoleService {
    readonly userRolesCombinedPermissions$: Observable<RolePermissions>;
    private rolesCollection: AngularFirestoreCollection<RoleInterface>;
    private currentUserRoles: RoleInterface[];
    private allRoles: Observable<RoleInterface[]>;

  public constructor(
    private readonly firestore: AngularFirestore,
    private readonly authService: AuthService
  ) {
    this.rolesCollection = this.firestore.collection<RoleInterface>(ROLES_COLLECTION);

    const firestoreDb = getFirestore();
    const roles$ = collectionData(collection(firestoreDb, ROLES_COLLECTION)) as Observable<RoleInterface[]>;

    this.userRolesCombinedPermissions$ = combineLatest({
      user: authService.user$,
      roles: roles$,
    }).pipe(
      map(({ user, roles }) => {
        if (user?.roles) return roles.filter((role) => user.roles.includes(role.name));
        if (user?.role) return roles.filter((role) => role.name === user.role);
      }),
      tap((roles) => (this.currentUserRoles = roles)),
      map((roles)=> this.getPermissions(roles))
    );

    this.userRolesCombinedPermissions$.subscribe();
  }

    public getAll(): Observable<RoleInterface[]> {
      if(!this.allRoles) {
        this.allRoles = this.rolesCollection.snapshotChanges().pipe(
            map((actions: DocumentChangeAction<RoleInterface>[]): RoleInterface[] => {
                return actions.map(
                    (action: DocumentChangeAction<RoleInterface>): RoleInterface => {
                        const role = action.payload.doc.data();
                        if (role) role.id = action.payload.doc.id;
                        return role;
                    }
                );
            }),
            shareReplay(1)
        );
      }
      return this.allRoles;
    }

    public getAllAllowed(): Observable<RoleInterface[]> {
      return this.getAll()
         .pipe(map((roles: RoleInterface[]): RoleInterface[] => {

                if (this.authService.hasRole(CONSTANTS.ROLE_KEYACCOUNT)) {
                    return roles;
                } else if (this.authService.hasRole(CONSTANTS.ROLE_ACCOUNT)) {
                    return roles.filter((role: RoleInterface): boolean => role.name !== CONSTANTS.ROLE_KEYACCOUNT);
                } else if (this.authService.hasRole(CONSTANTS.ROLE_PROPERTY)) {
                    return roles.filter(
                        (role: RoleInterface): boolean =>
                            role.name !== CONSTANTS.ROLE_KEYACCOUNT
                    );
                } else if (this.authService.hasRole(CONSTANTS.ROLE_ADMIN)) {
                    return roles.filter(
                        (role: RoleInterface): boolean =>
                            role.name !== CONSTANTS.ROLE_KEYACCOUNT
                    );
                } else if (this.authService.hasRole(CONSTANTS.ROLE_READER)) {
                    return roles.filter(
                        (role: RoleInterface): boolean =>
                            role.name !== CONSTANTS.ROLE_KEYACCOUNT &&
                            role.name !== CONSTANTS.ROLE_ACCOUNT &&
                            role.name !== CONSTANTS.ROLE_PROPERTY &&
                            role.name !== CONSTANTS.ROLE_ADMIN
                    );
                } else if (this.authService.hasRole(CONSTANTS.ROLE_NAVARRA)) {
                    return roles.filter(
                        (role: RoleInterface): boolean =>
                            role.name !== CONSTANTS.ROLE_KEYACCOUNT &&
                            role.name !== CONSTANTS.ROLE_ACCOUNT &&
                            role.name !== CONSTANTS.ROLE_PROPERTY &&
                            role.name !== CONSTANTS.ROLE_ADMIN &&
                            role.name !== CONSTANTS.ROLE_READER &&
                            role.name !== CONSTANTS.ROLE_AXEL &&
                            role.name !== CONSTANTS.ROLE_SERRA
                    );
                } else if (this.authService.hasRole(CONSTANTS.ROLE_AXEL)) {
                    return roles.filter(
                        (role: RoleInterface): boolean =>
                            role.name !== CONSTANTS.ROLE_KEYACCOUNT &&
                            role.name !== CONSTANTS.ROLE_ACCOUNT &&
                            role.name !== CONSTANTS.ROLE_PROPERTY &&
                            role.name !== CONSTANTS.ROLE_ADMIN &&
                            role.name !== CONSTANTS.ROLE_READER &&
                            role.name !== CONSTANTS.ROLE_NAVARRA &&
                            role.name !== CONSTANTS.ROLE_SERRA
                    );
                } else if (this.authService.hasRole(CONSTANTS.ROLE_SERRA)) {
                    return roles.filter(
                        (role: RoleInterface): boolean =>
                            role.name !== CONSTANTS.ROLE_KEYACCOUNT &&
                            role.name !== CONSTANTS.ROLE_ACCOUNT &&
                            role.name !== CONSTANTS.ROLE_PROPERTY &&
                            role.name !== CONSTANTS.ROLE_ADMIN &&
                            role.name !== CONSTANTS.ROLE_READER &&
                            role.name !== CONSTANTS.ROLE_NAVARRA &&
                            role.name !== CONSTANTS.ROLE_AXEL
                    );
                }
            })
        );
    }

    public getRolesByNames(roleNames: string[]): Observable<RoleInterface[]> {
        return this.rolesCollection
            .snapshotChanges()
            .pipe(
                map((actions: DocumentChangeAction<RoleInterface>[]) => {
                    this.currentUserRoles = actions.map(action =>
                        action.payload.doc.data())
                        .filter(role => role && roleNames.includes(role.name));
                    return this.currentUserRoles;
                })
            );
    }

    getRoleById(roleId: string) {
      return this.rolesCollection.doc<RoleInterface>(roleId).valueChanges();
    }

    updateRole(roleId: string, role: RoleInterface) {
      return this.rolesCollection.doc<RoleInterface>(roleId).update(role);
    }

    createRole(role: RoleInterface) {
      const roleId = this.firestore.createId();
      return this.rolesCollection.doc<RoleInterface>(roleId).set(role);
    }

    deleteRole(roleId: string) {
      return this.rolesCollection.doc(roleId).delete();
    }

    public mapRoleName(name: string): string {
      return TemporalNameMapper[name] || name ;
    }

    getPermissions(roles= this.currentUserRoles): RolePermissions {
      return roles
        ?.map(({ permissions }) => permissions)
        .reduce((acc, current) => {
          for (const key in current) {
            if (current[key]) acc[key] = true;
          }
          return acc;
        }, {});
    }

    hasPermission(permission: string): boolean {
      return this.currentUserRoles?.some((role) => role?.permissions?.[permission]);
    }

    getActivePermissionsKeys(): string[] {
      return Object.entries(this.getPermissions())
      .filter(([, value]) => !!value)
      .map(([key]) => key);
    }
}
