// TODO si va creciendo mucho subdividir por nombre de formulario
export const fieldTranslations = {
  // PRODUCT FORM
  root: 'General',
  metadata: 'Configuración características producto',
  expanded: 'Configuración textos producto',
  slug: 'Slug',
  description: 'Descripción',
  trialDays: 'Días gratis',
  subscriptionType: 'Tipo',
  images: 'Imágenes (índice):',
  seo: 'Configuración SEO',
  title: 'Título',
  summaryTitle: 'Título resumen del pedido',
  keywords: 'Palabra clave (índice):',
  languages: 'Internacionalización',
  es: 'Español',
  en: 'Inglés',
  htmlExtract: 'Extracto HTML',
  customerCondition: 'Tipo de cliente elegible',
  multiplePurchases: 'Compras máximas por usuario',
  stock: 'Stock',
  max: 'Máximo',
  stockMax: 'Stock máximo',
  prices: 'Configuración precios (índice):',
  tax: 'Impuesto',
  recurring: 'Recurrencia',
  intervalCount: 'Frecuencia',
  iterations: 'Iteraciones',
  unitAmount: 'Importe',
  // CONFIG - BASIC
  mediaConfig: 'Configuración Básica',
  public: 'Datos para ofrecer al usuario',
  termsAndConditionsUrl: 'Link términos y condiciones',
  termsAndConditionsFileUrl: 'Archivo términos y condiciones',
  stripe: 'Stripe',
  gracePeriod: 'Periodo de gracia',
  // CONFIG - HTML
  pages: 'Páginas',
  sections: 'Secciones',
  text: 'Contenido',
  // CONFIG- RRM
  plans: 'Configuración planes (identificador):',
  planName: 'Nombre del plan',
  products: 'Productos vinculados',
  trial: 'Periodo de gracia',
  price: 'Importe del plan',
  // GIGYA - MANUAL CONTRACT
  from: 'Inicio',
  until: 'Fin',
  // THALIA - PROMPT
  promptName: 'Nombre del prompt',
  role: 'Rol del prompt',
  prompt: 'Prompt',
  versions: 'Versiones',
  sitesArray: 'Medios',
  toGenerateTitles: 'Para generar títulos',
  // THALIA - MODELS
  parameters: 'Parámetros',
  models: 'Modelos',
  model: 'Nombre del modelo',
  maxTokens: 'Tokens máximos',
  key: 'Clave',
  value: 'Valor',
  medias: 'Medios',
  mediaTypes: 'Archivos permitidos',
  url: 'URL',
  asyncMinMb: 'Tamaño de archivo mínimo',
  // THALIA - CONTENT
  promptId: 'Prompt',
  modelId: 'Modelo',
  originalTitle: 'Título',
  originalSubtitle: 'Subtítulo',
  originalBody: 'Cuerpo',
  publishedUrl: 'URL de publicación',
  urls: 'URLs',
  titlesNumber: 'Número de títulos',
  // THALIA - TRANSCRIPTS
  file: 'Fichero',
  // SHARED
  name: 'Nombre',
};

export const errorTranslations = {
  // CONFIG FORM
  notAnInteger: 'Número con decimales',
  duplicateName: 'Nombre duplicado',
  // PRODUCT FORM
  invalidHtml: 'La descripción HTML introducida no es correcta',
  min: 'Valor incorrecto, debe ser mayor a cero',
  minlength: 'El campo introducido debe tener al menos tres carácteres',
  notDefaultLanguage: 'Falta el contenido del idioma predefinido',
  pattern: 'Solo se admiten letras en minúscula, números y guión medio',
  required: 'Campo sin rellenar',
  slugAlreadyExists: 'Ya existe para otro producto',
  isNotActiveTax: 'Impuesto no activo',
  invalidUrl: 'La URL introducida no es válida',
  notUnlimitedButNoMax: 'No ilimitado pero no se ha especificado un valor para stock máximo',
  pricesNotCorrectOrder:
    'Secuencia de precios con orden incorrecto, por favor póngase en contacto con el equipo técnico',
  isNotRenewable: 'RRM - El producto no es renovable',
  hasIntermediatePrices: 'RRM - El producto tiene fases intermedias',
  isNotSamePriceAmount: 'RRM - El producto tiene precios diferentes (inicial, renovación o precio del plan)',
  isNotSamePriceInterval: 'RRM - El producto tiene frecuencias diferentes (inicial, renovación o frecuencia del plan)',
  isNotSameTrial: 'RRM - El producto y el plan tienen diferentes días de prueba',
  isDuplicateName: 'RRM - Ya existe un plan con ese nombre',
  isDuplicateId: 'RRM - Ya existe un plan con ese ID',
  // SHARED
  fromIsLaterThanUntil: 'La fecha de inicio debe ser anterior a la fecha de fin',
  notSameLength: 'Los elementos no contienen el mismo número de datos',
  notSameValues: 'Ambos elementos no contienen los mismos datos',
  hasNotValuesOnArray: 'El valor no está en la lista permitida',
  stripeAllowedIntervals: 'El valor no está en las frecuencias permitidas para Stripe',
  rrmAllowedIntervals: 'El valor no está en las frecuencias permitidas para RRM',
  errorOnSanitize: 'HTML con posible contenido malicioso o mal formado. Por favor, revisa el contenido',
  diacritics: 'No se admiten caracteres diacríticos',
  nonPrintableCharacters: 'No se admiten caracteres no imprimibles',
  spaces: 'No se admiten espacios',
  arrayMinLength: 'Falta por añadir al menos un contenido',
  hasRepeatValueInArray: 'No se admiten valores repetidos',
  atLeastOneControl: 'Debe introducir al menos un valor',
  // THALIA - PROMPT
  hasActiveMultipleTitles: 'Ya existe un prompt activo para generar títulos',
};
