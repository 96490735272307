<div class="row">
    <div class="col-md-5">
        <h2>{{ initialListTitle }}</h2>
        <div class="row">
            <mat-form-field class="col-md-12 c-mat-form c-mat-form--no-padding p-0" appearance="outline">
                <mat-label>Filtrado por nombre</mat-label>
                <input type="text" placeholder="Nombre del segmento" matInput #filter (input)="onChangeFilter($event.target.value)"/>
            </mat-form-field>
        </div>
        <div class="row">
            <div class="col-md-12 card px-2 border radius overflow-auto">
                <mat-list dense class="c-segments-dual-list-size">
                    <mat-list-item
                        *ngFor="let item of filteredData"
                        class="my-2 border rounded "
                        [value]="item.id"
                        [matTooltip]="item.id"
                        matTooltipPosition="left"
                        [ngClass]="{ elevation: item.selected }"
                        [selected]="item.selected"
                        (click)="toggleItem(item)"
                        >
                        <div class="text-nowrap overflow-hidden">{{ item.name }}</div>
                        <mat-chip-list *ngIf="showChips" class="ml-auto">
                            <mat-chip color="accent" selected>{{ chipsTitles[item.type] }}</mat-chip>
                        </mat-chip-list>
                    </mat-list-item>
                </mat-list>
            </div>
        </div>
    </div>

    <div class="col-md-2 align-self-center">
        <div class="row">
            <div class="col-md-12">
                <button mat-raised-button class="w-100 my-2" color="primary" (click)="addAll()" [disabled]='isInTestAB || isRetailMedia'>
                    Añadir todos<mat-icon class="lh-base" fontSet="fas" fontIcon="fa-angle-right"></mat-icon>
                </button>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <button
                    mat-raised-button
                    class="w-100 my-2"
                    color="primary"
                    (click)="add()"
                    [disabled]="!areInitialItemsSelected || isInTestAB || isRetailMedia"
                >
                    Añadir<mat-icon class="lh-base" fontSet="fas" fontIcon="fa-angle-right"></mat-icon>
                </button>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <button
                    mat-raised-button
                    class="w-100 my-2"
                    color="primary"
                    (click)="delete()"
                    [disabled]="!areFinalItemsSelected || isInTestAB || isRetailMedia"
                >
                    Eliminar <mat-icon class="lh-base" fontSet="fas" fontIcon="fa-angle-left"></mat-icon>
                </button>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <button mat-raised-button class="w-100 my-2" color="primary" (click)="deleteAll()" [disabled]='isInTestAB || isRetailMedia'>
                    Eliminar todos<mat-icon class="lh-base" fontSet="fas" fontIcon="fa-angle-left"></mat-icon>
                </button>
            </div>
        </div>
    </div>

    <div class="col-md-5">
        <h2>{{ finalListTitle }}</h2>
        <div *ngIf="requiresSegmentsOperator" class="row py-3">
          <section class="col-md-12">
            <mat-radio-group class="row" [(ngModel)]="segmentsOperator" (change)="changeSegmentsOperator()">
              <mat-radio-button class="col-6 custom-checkbox-text" color="primary"
                value="or">Se debe cumplir al menos 1 de los segmentos
              </mat-radio-button>
              <mat-radio-button class="col-6 custom-checkbox-text" color="primary"
                value="and">Se deben cumplir todos los segmentos
              </mat-radio-button>
            </mat-radio-group>
          </section>
        </div>

        <div class="row" [ngClass]="{ 'pt-6': !requiresSegmentsOperator, 'pt-1': requiresSegmentsOperator }">
            <div class="col-md-12 card px-2 border radius overflow-auto">
                <mat-list dense class="c-segments-dual-list-size">
                    <mat-list-item
                        *ngFor="let item of finalData"
                        class="my-2 border rounded "
                        [value]="item.id"
                        [matTooltip]="item.id"
                        matTooltipPosition="left"
                        [ngClass]="{ elevation: item.selected }"
                        [selected]="item.selected"
                        (click)="toggleItem(item)"
                        >{{ item.name }}
                        <mat-chip-list *ngIf="showChips" class="ml-auto">
                            <mat-chip color="accent" selected>{{ chipsTitles[item.type] }}</mat-chip>
                        </mat-chip-list>
                    </mat-list-item>
                </mat-list>
            </div>
        </div>
    </div>
</div>
