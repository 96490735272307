import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PushNotificationInterface } from 'src/app/core/interfaces/push-notification/push-notification.interface';
import { CONSTANTS } from 'src/app/shared/utils/constants';

@Component({
    selector: 'app-type-notification-form',
    templateUrl: './type-form.component.html',
    styleUrls: ['./type-form.component.scss'],
})
export class TypeNotificationFormComponent implements OnInit, OnDestroy {
    @Input() formGroup: UntypedFormGroup;
    @Input() pushNotification: PushNotificationInterface;

    private unsubscribe: Subject<void> = new Subject();

    public messageTypes: number[] = [
        CONSTANTS.PROMETEO_SCHEDULES_NOW_TYPE,
        CONSTANTS.PROMETEO_SCHEDULES_SCHEDULED_TYPE,
    ];

    public contentTypes: number[] = [
        CONSTANTS.PROMETEO_SCHEDULES_MESSAGE_MANUAL_TYPE,
        CONSTANTS.PROMETEO_SCHEDULES_MESSAGE_DYNAMIC_TYPE,
    ];

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    constructor() {}

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    ngOnInit(): void {}

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    public subscribeToFormTypes(): void {
        this.formGroup
            .get('firm')
            .valueChanges.pipe(takeUntil(this.unsubscribe))
            .subscribe((firm: string): void => {
                this.pushNotification.firm = firm;
            });
    }
}
