import { AbstractControl, UntypedFormArray, UntypedFormGroup, ValidationErrors } from '@angular/forms';
import { errorTranslations, fieldTranslations } from '../constants-error-list';

export interface RecursiveErrorsNode {
  label: string;
  errors: ValidationErrors;
  children: RecursiveErrorsNode[];
}

export function recursiveErrors(control: AbstractControl, label = 'root'): RecursiveErrorsNode {
  if (!control) return;

  const children: RecursiveErrorsNode[] = [];
  if (control instanceof UntypedFormArray || control instanceof UntypedFormGroup) {
    if (Object.keys(control.controls).length) {
      children.push(
        ...Object.entries(control.controls)
          .map(([label, control]) => recursiveErrors(control, label))
          .filter(Boolean)
      );
    }
  }

  if (!control.errors && !children.length) return;

  return {
    label,
    errors: control.errors,
    children,
  };
}

function translateField(key: string): string {
  return fieldTranslations?.[key] ?? key;
}

function translateError(key: string): string {
  return errorTranslations?.[key] ?? key;
}

function getKeys(errors: ValidationErrors): string[] {
  return Object.keys(errors);
}

export function getHTMLErrorList(recursiveErrorsNode: RecursiveErrorsNode): string {
  if (!recursiveErrorsNode) return null;

  let html = '';
  const lineBreak = '<br />';

  if (isNaN(Number(recursiveErrorsNode.label)) && recursiveErrorsNode.label !== 'root') {
    html += `<span><strong>${translateField(recursiveErrorsNode.label)}: </strong></span>`;
  }

  if (recursiveErrorsNode.children.length && isNaN(Number(recursiveErrorsNode.label))) {
    html += lineBreak;
  }

  if (recursiveErrorsNode.errors) {
    getKeys(recursiveErrorsNode.errors).forEach(key => {
      html += `<span>${translateError(key)}</span>`;
    });
    html += lineBreak;
  }


  if (recursiveErrorsNode.children) {
    recursiveErrorsNode.children.forEach(child => {
      const childElement = getHTMLErrorList(child);
      if (childElement) {
        html += childElement;
      }
    });
  }

  return html;
}
