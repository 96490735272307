<app-generic-table-title
    title="Test A/B Activadores"
    buttonText="Añadir Test A/B"
    [buttonEnabled]="canCreate"
    buttonLink="./create">
</app-generic-table-title>

<div class="elevation card mx-5 p-3" *ngIf="testAbList && testAbList.filteredData">
    <app-generic-filter-select class="mr-3"
        [sections]="sections"
        [componentList]="testAbList.filteredData"
        inputNameText="Nombre del Test A/B"
        inputPlaceholderNameText="Seleccione un Test A/B"
        inputIdText="Id del Test A/B"
        inputPlaceholderIdText="Seleccione un id de Test A/B"
        downloadButtonText="Descargar Listado de Test A/B"
        (filtersUpdated)="filter($event)"
        (exportListAsCSV)="exportContentsListAsCSV()">
    </app-generic-filter-select>
</div>

<app-spinner-wrapper [isLoading]="!testAbList || !testAbList.filteredData">
    <app-generic-table
        [thereIsData]="!!testAbList && testAbList.filteredData.length > 0"
        [dataSource]="testAbList"
        [columns]="tableData"
        [actions]="actions"
        tableStyle="c-triggers-table"
        (actionChange)="onActionChange($event.element, $event.event)"
        (buttonClick)="onButtonStatisticsClicked($event.element)">
    </app-generic-table>
</app-spinner-wrapper>
