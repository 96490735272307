import { Component, Input, OnChanges, OnInit, OnDestroy } from '@angular/core';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { ExcelService } from 'src/app/shared/services/excel.service';
import { TableColumn } from 'src/app/shared/components/generic-table/column.interface';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MetricService } from 'src/app/core/services/metric.service';
import { TriggererCategory } from 'src/app/core/interfaces/statistics-triggers-category.interface';
import { DecimalPipe } from '@angular/common';
import { ConstantsStatistics } from '../../utils/constants-statistics';

@Component({
  selector: 'app-statistics-categories',
  templateUrl: './statistics-categories.component.html',
  styleUrls: ['./statistics-categories.component.scss']
})
export class StatisticsCategoriesComponent implements OnInit, OnChanges, OnDestroy {
  @Input() triggerId: string;
  @Input() dates: { begin: Date, end: Date };
  @Input() currentSiteId: string;


  public metricsCategoriesTable: MatTableDataSource<TriggererCategory>;
  public metricsCategories: TriggererCategory[];

  private unsubscribe: Subject<void> = new Subject<void>();
  public categoriesLoading: boolean = true;
  public categoriesFiltersForm: UntypedFormGroup;
  public structureCategoriesColumns: TableColumn<TriggererCategory>[] = [
    {
      Title: 'Categoría',
      columnDef: 'name',
      type: 'text',
      Value: (category: TriggererCategory) =>category.name,
      isSortable: true,
    },
    {
      Title: 'Impresiones',
      columnDef: 'shows',
      type: 'text',
      Value: (category: TriggererCategory) => category.impressions,
      isSortable: true,
    },
    {
      Title: 'Acción',
      columnDef: 'action',
      type: 'text',
      Value: (category: TriggererCategory) => category.activate,
    },
    {
      Title: 'Cierre',
      columnDef: 'close',
      type: 'text',
      Value: (category: TriggererCategory) =>category.closed,
      isSortable: true,
    },
    {
      Title: 'Acción 1',
      columnDef: 'action1',
      type: 'text',
      Value: (category: TriggererCategory) => category.activate1,
      isSortable: true,
    },
    {
      Title: 'Acción 2',
      columnDef: 'action2',
      type: 'text',
      Value: (category: TriggererCategory) => category.activate2,
      isSortable: true,
    }
  ];

  constructor(
    private readonly excelService: ExcelService,
    private readonly formBuilder: UntypedFormBuilder,
    private readonly metricService: MetricService,
    private readonly decimalPipe: DecimalPipe
  ) { }

  ngOnInit(): void {
    this.createFiltersForm();
    this.subscribeToFilters();
  }

  ngOnChanges() {
    this.categoriesLoading = true;
    this.unsubscribe.next();
    this.loadData();
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  private createFiltersForm(): void {
    this.categoriesFiltersForm = this.formBuilder.group({
      name: [''],
    });
  }

  private subscribeToFilters(): void {
    this.categoriesFiltersForm.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe((): void => this.filter());
  }

  public filter(): void {
    this.metricsCategoriesTable.filter = JSON.stringify(this.categoriesFiltersForm.value);
  }


  public getCategoriesCTRFormated(interactions: number, impressions: number): string {
    const CTR = interactions && impressions ? (interactions / impressions)*100 : 0;
    let ctrString = CTR.toFixed(2).toString();
    if (parseInt((CTR / 10).toString()) <= 0) ctrString = '0' + ctrString;

    return ' (' + ctrString + '%)';
  }

  public loadData() :void {
    this.metricService.getCategoriesTriggerStats(this.triggerId, this.currentSiteId, this.dates.begin, this.dates.end).pipe(
      takeUntil(this.unsubscribe)).subscribe((triggerMetricsCategories: TriggererCategory[]): void => {
        this.metricsCategories = triggerMetricsCategories;
        this.metricsCategoriesTable = new MatTableDataSource(this.metricsCategories);
        this.metricsCategoriesTable.filterPredicate = (data: TriggererCategory, filter: string): boolean => {
          const parsedFilters = JSON.parse(filter);
          return Object.keys(parsedFilters).every(column => {
              const fieldValue = data[column];
              const filterValue = parsedFilters[column];
              switch (column) {
                case 'name':
                  return fieldValue.toLowerCase().indexOf(filterValue.toLowerCase()) > -1;
                default:
                  return false;
              }
          });
        };

        this.metricsCategoriesTable.sortingDataAccessor = (item: TriggererCategory, property: string): string | number => {
          switch (property) {
              case 'name':
                return item.name.toLowerCase();
              case 'shows':
                return item.impressions;
              default:
                return item[property];
          }
      };
    this.categoriesLoading = false;
    });
  }

  private adaptTriggerCategoriesMetricsToCSV(): any[] {
    const metricsDTO: {
      Categoria: string;
      Show: string;
      Close: string;
      Action: string;
      Action1: string;
      Action2: string;
    }[] = this.metricsCategoriesTable.filteredData.map(category => ({
        Categoria: category.name,
        Show: this.decimalPipe.transform(category.impressions, '', ConstantsStatistics.decimalPipeLocale),
        Close: this.decimalPipe.transform(category.closed, '', ConstantsStatistics.decimalPipeLocale),
        Action: this.decimalPipe.transform(category.activate, '', ConstantsStatistics.decimalPipeLocale),
        Action1: this.decimalPipe.transform(category.activate1, '', ConstantsStatistics.decimalPipeLocale),
        Action2: this.decimalPipe.transform(category.activate2, '', ConstantsStatistics.decimalPipeLocale),

    }));

    return metricsDTO.sort((a, b) => Number(b.Show.replace(/\./g, '')) - Number(a.Show.replace(/\./g, '')));
  }

  public exportCategoriesMetricsAsCSV(): void {
    const metrics = this.adaptTriggerCategoriesMetricsToCSV();
    const fileName = 'trigger-categories-metrics-' + new Date().toLocaleDateString() + '.xlsx';
    this.excelService.exportAsExcelFile(metrics, fileName);
  }
}
