import { UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Utils } from './utils';

function getFormControl(value, validators?, asyncValidators?): UntypedFormControl {
  if (validators && asyncValidators) return new UntypedFormControl(value, validators, asyncValidators);
  if (validators) return new UntypedFormControl(value, validators);
  if (asyncValidators) return new UntypedFormControl(value, [], asyncValidators);
  else return new UntypedFormControl(value);
}

interface CreateFormGroupParams<T> {
  data: T;
  validators?: NonNullable<unknown>;
  asyncValidators?: NonNullable<unknown>;
  dateTransform?: (date: Date) => Date | string;
}

export function createFormGroup<T>({
  data,
  validators = {},
  asyncValidators = {},
  dateTransform = (date: Date) => Utils.getHtmlLocalDateTime(Utils.siftSeconds(date)), // WARNING muy acoplado a caso de uso paywall, cuidado al usar fuera.
}: CreateFormGroupParams<T>): UntypedFormGroup {
  const formGroup = new UntypedFormGroup({});
  for (const [key, value] of Object.entries(data)) {
    if (Utils.isDate(value)) {
      const formControl = getFormControl(dateTransform(value), validators[key], asyncValidators[key]);
      formGroup.addControl(key, formControl);
      continue;
    }
    if (Utils.isArray(value)) {
      const controlsConfig = value.map((item) => {
        if (Utils.isObject(item))
          return createFormGroup({ data: item, validators: validators[key], asyncValidators: asyncValidators[key] });
        return getFormControl(item, validators[key], asyncValidators[key]);
      });
      formGroup.addControl(key, new UntypedFormArray(controlsConfig));
      continue;
    }
    if (Utils.isObject(value)) {
      formGroup.addControl(
        key,
        createFormGroup({ data: value, validators: validators[key], asyncValidators: asyncValidators[key] })
      );
      continue;
    }
    formGroup.addControl(key, getFormControl(value, validators[key], asyncValidators[key]));
  }
  return formGroup;
}

export function genericGroupValidator<T>(classObject: T, minLength = 3): any {
  const validator = {};
  Object.entries(classObject).forEach(([key, item]) => {
    if (typeof item === 'string' || item instanceof String) {
      validator[key] = [Validators.required, Validators.minLength(minLength)];
    } // TODO Review if more levels of depth are required
  });
  return validator;
}
