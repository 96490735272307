import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MediaInterface } from 'src/app/core/interfaces/media.interface';
import { MediaService } from 'src/app/core/services/media.service';
import { CONSTANTS } from 'src/app/shared/utils/constants';
import _ from "lodash";

@Component({
  selector: 'app-generic-statistics-selector',
  templateUrl: './generic-statistics-selector.component.html',
  styleUrls: ['./generic-statistics-selector.component.scss'],
})
export class GenericStatisticsSelectorComponent implements OnInit {

  @Input() elementsList: any[];
  @Input() dates: { begin: Date, end: Date };
  @Input() statisticType: string;
  @Input() elementId: any;
  @Output() datesChanged = new EventEmitter<{ dates: { begin: Date, end: Date } }>(); // detecta los cambios de fechas y los exporta (añadir en generic-filter-select)
  @Output() elementSelected = new EventEmitter<{ element: any }>();
  public selectorName = "";
  public currentSiteId: string;
  public medias: MediaInterface[];
  public filtersForm: UntypedFormGroup;
  public maxDate = new Date();
  public minDate = new Date();
  public initialElementsList: any[];

  constructor(
    private readonly formBuilder: UntypedFormBuilder,
    private readonly mediaService: MediaService
  ) {}

  ngOnInit() {
    this.mediaService.getAll()
        .subscribe(([medias]: [MediaInterface[], any]): void => {
            this.medias = medias;
            const startDate = this.dates.begin;
            const endDate = this.dates.end;
            if (this.statisticType === "segment") {
              this.elementsList = this.elementsList.map(e => {
                const id = e.segmentID ? e.segmentID : e.idRule;
                return { ...e, id };
              });
            }
            this.minDate.setMonth(this.maxDate.getMonth() - CONSTANTS.STATISTICS_MONTH_INTERVAL);
            this.initialElementsList = _.cloneDeep(this.elementsList);
            const element = this.elementsList.find(element => element.id === this.elementId);
            this.createFiltersForm(startDate, endDate, element);
            this.subscribeToFilters();
            this.initializeSelectorName();
        });
  }

  private createFiltersForm(startDate: Date, endDate: Date, trigger: any): void {
    this.filtersForm = this.formBuilder.group({
        elements: [ trigger ? this.getElementName(trigger) : ''],
        dates: this.formBuilder.group({
          begin: [startDate],
          end: [endDate]
        }),
    });
  }

  private subscribeToFilters(): void {
    this.filtersForm.get("dates").valueChanges.subscribe(dates => {
      this.datesChanged.emit({ dates });
    });

    this.filtersForm.get("elements").valueChanges.subscribe(elementName => {
      this.initialElementsList = this.elementsList.filter(filteredElement => {
          const filteredElementName: string = this.getElementName(filteredElement);
          this.onElementSelected(filteredElement.id);
          return elementName ? filteredElementName.toLowerCase().indexOf(elementName.toLowerCase()) > -1 : true;
      });

    });
  }

  public onElementSelected(element: any) {
    this.elementSelected.emit({ element });
  }

  public getElementName(element: any): string {
    switch (this.statisticType) {
        case 'widget':
        case 'datasource':
        case 'trigger':
        case 'pushNotificationSubscription':
        case 'test-ab':
          return element.name;
        case 'pushNotificationTopic':
          return (element.topic && element.topic.topic) || element.topic; //TODO apaño por estructura de datos incorrecta. Eliminar cuando se corrija.
        case 'newsletter':
          return element.title;
        case 'segment':
          return element.segmentName ? element.segmentName : element.name;
        default:
          return "";
    }
  }

  private initializeSelectorName(): void {
    switch (this.statisticType) {
      case 'widget':
        this.selectorName = "Seleccione un widget";
        break;
      case 'trigger':
        this.selectorName = "Seleccione un activador";
        break;
      case 'newsletter':
        this.selectorName = "Seleccione una suscripción newsletter";
        break;
      case 'pushNotificationSubscription':
          this.selectorName = "Seleccione una suscripción notificación push";
          break;
      case 'pushNotification':
        this.selectorName = "Seleccione una notificación push";
        break;
      case 'segment':
        this.selectorName = "Seleccione un segmento";
        break;
      case 'test-ab':
        this.selectorName = "Seleccione un test A/B";
        break;
      default:
        this.selectorName = "Seleccione una fuente de datos";
        break;
    }
  }
}
