import { Injectable } from '@angular/core';
import DOMPurify from 'dompurify';

@Injectable()
export class HtmlSanitizerService {
  private readonly purify = DOMPurify();

  public constructor() {
    this.purify.setConfig({
      ALLOWED_ATTR: ['style', 'class', 'id', 'title', 'src', 'href', 'alt', 'name'],
      ALLOWED_TAGS: [
        'div',
        'span',
        'p',
        'a',
        'img',
        'strong',
        'em',
        'u',
        'i',
        'b',
        'ul',
        'li',
        'ol',
        'br',
        'hr',
        'h1',
        'h2',
        'h3',
        'h4',
        'h5',
        'h6',
      ],
    });
  }

  getSanitizedHtml(html: string): string {
    return this.purify.sanitize(html);
  }
}
