import { Injectable } from '@angular/core';
import CryptoES from 'crypto-es';

@Injectable()
export class CryptoService {
  encrypt({ key, data }: { key: string; data: unknown }): string {
    if (!key) throw new Error('No key provided');
    const payload = JSON.stringify(data);
    return CryptoES.AES.encrypt(payload, key).toString();
  }

  decrypt({ key, data }: { key: string; data: string }): unknown {
    if (!key) throw new Error('No key provided');
    if (!data) throw new Error('No data provided');
    try {
      const payload = CryptoES.AES.decrypt(data, key).toString(CryptoES.enc.Utf8);
      return JSON.parse(payload);
    } catch (error) {
      throw new Error('Error decrypting payload');
    }
  }
}
